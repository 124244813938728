import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SHeader, STable, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import SelectGoods from '../../../../components/SelectGoods';

const Summary = ({ goodsStock, goods }) => {
    const [dialogDisplay, setDialogDisplay] = useState(false);
    const navigate = useNavigate();

    const handleShowDialog = () => {
        if (goods?.GoodsSubNum > 0) {
            //商品サブ検索を開く
            navigate(`/goods/${goods.GoodsID}/sub`, {});
        } else {
            setDialogDisplay(true);
        }
    }
    return (
        <Wrapper>
            <SHeader>
                <span>概要</span>
                <button onClick={handleShowDialog} className='btn'>選択</button>
                <SelectGoods goods={goods} dialogDisplay={dialogDisplay} setDialogDisplay={setDialogDisplay} />
            </SHeader>
            <STable>
                <table>
                    <thead>
                        <tr>
                            <th>区分</th>
                            <th>価格（P）</th>
                            <th>発行</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goodsStock.map(stock => (
                            <tr key={stock.ServiceTypeID}>
                                <td>{stock.ServiceType}</td>
                                <td className='align-right'>{Intl.NumberFormat('ja-JP').format(stock.Price)}</td>
                                <td>{stock.DatePublished.substring(0, 10) || '--'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </STable>
        </Wrapper>
    )
}


Summary.propTypes = {
    goodsId: PropTypes.string,
    goodsStock: PropTypes.array,
    goodsSubCount: PropTypes.number,
}

Summary.defaultProps = {
    goodsId: '999',
    goodsStock: [],
    goodsSubCount: 0,
}

export default Summary
