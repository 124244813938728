import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, Title, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setClient } from '../../../../redux/cartSlice';

const ClientItem = ({ item }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handlClick = () => {
        dispatch(setClient({
            clientID: item.ClientID,
            clientName: item.ClientName,
        }))
        navigate('/sales-slip');
    }
    return (
        <Wrapper onClick={handlClick}>
            <Content>
                <Title>{item.ClientName}</Title>
                <Line>
                    〒{item.PostalCode}　{item.Pref || ''}{item.Adr || ''}
                </Line>
                <Line
                >
                    <div style={{ display: "inline-block" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        TEL: <a href={`tel:${item.Tel}`}>{item.Tel || "-"}</a>
                    </div>
                </Line>
            </Content>
        </Wrapper>
    )
}

ClientItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ClientItem;
