import styled from 'styled-components';

export const Wrapper = styled.div`
    font-size: var(--fontSmall);
    padding-bottom:2px;
    border-bottom: 2px solid var(--lightGreen);

    .item-title{
        display: flex;
        background-color:var(--lightGreen);
        padding: 2px 5px;
        font-weight: 500;
    }
    .pl-30{
        padding-left: 30px;
    }
        
    label{
        padding: 0 5px;
        color: #6c757d;
        font-size:var(--fontSSmall);
    }
`;