import styled from 'styled-components';

export const Wrapper = styled.div`
    font-size: var(--fontSSmall);
    padding-bottom:2px;
    border-bottom: 2px solid #f0f0f0;

    .pl-30{
        padding-left: 30px;
    }
           
`;

export const Title = styled.div`
    display: flex;
    background-color:#f0f0f0;
    padding: 2px 5px;
    font-weight: 500;
    font-size:var(--fontSmall);
`
export const Row = styled.div`
    display:flex;

    .col-left{
        flex:1;
    }
    .col-right{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
`
export const Line = styled.div`
`

export const MLabel = styled.small`
    padding: 0 5px;
    color: #6c757d;
`


export const ButtonSelect = styled.button`
    font-size: 1rem;
    padding: 3px 10px;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 3px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`
