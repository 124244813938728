import axiosClient from "./axiosClient";

const siteApi = {

    search(params) {
        const url = '/site';
        return axiosClient.get(url, { params: params });
    },
    register(params) {
        return axiosClient.post("/site-new", params);
    },

}

export default siteApi;
