import React, { createRef, useEffect } from 'react';
import HeaderBox from 'components/HeaderBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import { Content, DataEmpty, Wrapper } from './styles';
import { useClientAction } from './useClientAction';
import ActionItem from './components/ActionItem';
import ActionDetails from './components/ActionDetails';

const ClientAction = () => {
    const { actionList,
        clientAction,
        setClientAction,
        termSearch,
        setTermSearch,
        isLoading,
        message,
        setMessage } = useClientAction();

    const refTop = createRef();
    useEffect(() => {
        if (!!refTop?.current) refTop.current.scrollTo(0, 0);
        return;
    }, [actionList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='訪問実績' showCart={true} />
            <MessageBox message={message} setMessage={setMessage} />
            {!clientAction && <>
                <SearchBox textHolder='お客様名' termSearch={termSearch} setTermSearch={setTermSearch} />
                <Content ref={refTop}>
                    {actionList?.length > 0 ? (
                        actionList.map((item) => {
                            return (<ActionItem key={item.ClientActionID} item={item} handleClick={() => { setClientAction({ ...item }) }} />)
                        })
                    ) : (<DataEmpty>データなし</DataEmpty>)}
                </Content>
            </>}
            {!!clientAction && <ActionDetails clientAction={clientAction} setClientAction={setClientAction} />}
        </Wrapper>
    )
}

export default ClientAction