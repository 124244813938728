import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Cart, GoodsNum, HeaderTitle, Label, Wrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import NavMenu from '../NavMenu';
import HeaderCart from '../HeaderCart';

const HeaderBox = ({ title, showCart }) => {
    const cart = useSelector((state) => state.cart);
    return (
        <Wrapper>
            <NavMenu idMenu="id_menu" />
            <Label htmlFor='id_menu' className='button icon borderRight ' >
                <FontAwesomeIcon icon={faBars} />
            </Label>
            <HeaderTitle>{title}</HeaderTitle>
            {showCart &&
                <>
                    <Cart className='button icon borderLeft' htmlFor='idCart'>
                        <FontAwesomeIcon icon={faCartShopping} />
                        {cart?.goodsList?.length > 0 ? <GoodsNum>{cart.goodsList.length}</GoodsNum> : null}
                    </Cart>
                    <HeaderCart idCart={"idCart"} cart={cart} />
                </>
            }
        </Wrapper>
    )
}

HeaderBox.propTypes = {
    title: PropTypes.string,
    showCart: PropTypes.bool,
}

HeaderBox.defaultProps = {
    title: '',
    showCart: false,
}

export default HeaderBox
