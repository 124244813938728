import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;

export const SHeader = styled.div`
    padding: 3px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:600;
`;

export const SalesGoods = styled.div`
    padding-bottom: 10px;
`;