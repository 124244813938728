import axiosClient from "./axiosClient";

const loginApi = {
    login(params) {
        const url = '/login';
        return axiosClient.post(url, params);
    },
    logout() {
        const url = '/logout';
        return axiosClient.post(url);
    }
}

export default loginApi
