import axiosClient from "./axiosClient";

const companyApi = {
    search(params) {
        const url = '/company';
        return axiosClient.get(url, { params: params });
    },
}

export default companyApi;
