import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ececec;
    text-decoration: none;
    color: #000;   
`;



export const Content = styled.div`
    flex: 1;
    display:flex;
    flex-direction: column;
    padding: 5px 5px;

    :hover{
        background-color: #ececec;
        cursor:pointer;
    }
`
export const Title = styled.span`
    color:var(--morienGreen);
    font-weight:bold;
`
export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`
