import React, { createRef, useEffect } from 'react';
import HeaderBox from 'components/HeaderBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import CompanyItem from './components/CompanyItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useOccation } from './useCompany';

const Company = () => {
    const { companyList, termSearch, setTermSearch, isLoading, message, setMessage } = useOccation();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [companyList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='法人個人検索' />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='電話番号、名前' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content Content ref={refTop} >
                {companyList?.length > 0 ? (
                    companyList.map((item) => {
                        return (<CompanyItem key={`${item.ObjectID}-${item.ObjNumberID}`} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)
                }
            </Content >
        </Wrapper >
    )
}

export default Company