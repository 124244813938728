import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'
import { BtnClose, BtnQuantity, Button, ButtonSelect, Content, Dialog, DialogContent, DialogTitle, Line, MLabel, Overlay, Quantity, RightControl, TextContent, TextInput, TextInputWrapper, TextTile, Title, Wrapper, WrapperQuantity } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { addGoods } from '../../../../redux/cartSlice'
import { MESSAGE } from '../../../../constants';

const GoodsSubItem = ({ item, setMessage }) => {
    const [quantity, setQuantity] = useState(1);
    const [dialogDisplay, setDialogDisplay] = useState(false);
    const [nameSub, setNameSub] = useState('');
    const [kanaSub, setKanaSub] = useState('');
    const dispatch = useDispatch();

    const handlePlus = () => {
        setQuantity(quantity + 1);
    }

    const handleMinus = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const handleSelectGoods = () => {

        if (item.GoodsSubID < 1 && nameSub.trim() === '') {
            const err = {
                type: MESSAGE.ERROR,
                contents: ['色名を必須入力とする。']
            }
            setMessage(err);
            return;
        }

        const q = quantity;
        const goods = {
            goodsID: item.GoodsID,
            goodsName: item.GoodsSubID > 0 ? item.GoodsSubName : item.GoodsName + nameSub.trim(),
            goodsKana: item.GoodsSubID > 0 ? item.GoodsSubKana : item.GoodsKana + kanaSub.trim(),
            goodsSize: item.GoodsSize,
            goodsSubID: item.GoodsSubID > 0 ? item.GoodsSubID : 0,
            quantity: q,
        }
        dispatch(addGoods(goods));
        setQuantity(1);
        setDialogDisplay(false);
    }

    return (
        <Wrapper>
            <Content>
                <Title>{item.GoodsSubName || '-'}</Title>
                <Line>
                    <MLabel>サイズ：</MLabel>
                    {item.GoodsSize || '―'}
                </Line>
                <Line>
                    <MLabel>発効：</MLabel>{item.DatePublished || `～`}
                    <MLabel style={{ marginLeft: '20px' }}>失効：</MLabel>{item.DateLimit || `～`}
                </Line>
            </Content>
            <RightControl>
                <Button onClick={() => { setDialogDisplay(true); }}>選択</Button>
            </RightControl>

            <Dialog isDisplay={dialogDisplay}>
                <DialogTitle>
                    <TextTile>数量選択</TextTile>
                    <BtnClose onClick={() => {
                        setDialogDisplay(false);
                    }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
                </DialogTitle>
                <DialogContent>
                    <TextContent>
                        <Line>
                            <MLabel>商品：</MLabel>{item.GoodsSubID < 1 ? `${item.GoodsName}　${nameSub}` : item.GoodsSubName}
                        </Line>
                        <Line>
                            <MLabel>サイズ：</MLabel>{item.GoodsSize || '-'}
                        </Line>
                    </TextContent>

                    {item.GoodsSubID < 1 ? (<TextInputWrapper>
                        <TextInput size="20"
                            maxLength="30"
                            placeholder="色名"
                            value={nameSub}
                            onChange={(e) => { setNameSub(e.target.value) }} />

                        <TextInput size="20"
                            maxLength="30"
                            placeholder="イロメ（カナ）"
                            value={kanaSub}
                            onChange={(e) => { setKanaSub(e.target.value) }} />
                    </TextInputWrapper>) : (<></>)}

                    <WrapperQuantity>
                        <BtnQuantity onClick={handleMinus}><FontAwesomeIcon icon={faMinus} /></BtnQuantity>
                        <Quantity>{quantity}</Quantity>
                        <BtnQuantity onClick={handlePlus} ><FontAwesomeIcon icon={faPlus} /></BtnQuantity>
                    </WrapperQuantity>
                    <ButtonSelect onClick={() => { handleSelectGoods(); }} >選択</ButtonSelect>
                </DialogContent>
            </Dialog>
            <Overlay isDisplay={dialogDisplay} />
        </Wrapper>
    )
}

GoodsSubItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default GoodsSubItem;
