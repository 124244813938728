import React from 'react';

import { Wrapper } from './styles';

const StockInfoItem = ({ stockInfoItem }) => {
    return (
        <Wrapper>
            <div className='item-title'>
                <span>{stockInfoItem.GoodsName}</span>
            </div>
            <div>
                <span><label>入荷数：</label>{stockInfoItem.ReceivedNumber}</span>
            </div>
            <div>
                <span><label>発注日：</label>{stockInfoItem.OrderedDate.substring(0, 10) || '--'}</span>
            </div>
            <div>
                <span><label>発注者：</label>{stockInfoItem.OrderedStaffName}</span>
            </div>
            <div>
                <span><label>在庫場所：</label>{stockInfoItem.Shelf}</span>
            </div>

        </Wrapper>
    )
}

export default StockInfoItem
