import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MESSAGE } from 'constants';
import actionApi from 'api/actionApi';


export const useClientAction = () => {

    const location = useLocation();
    const objSearch = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const obj = {
            txtSearch: search.get("search") || '',
        };
        return obj;
    }, [location.search]);

    const [termSearch, setTermSearch] = useState({ txtSearch: objSearch?.txtSearch || '' });
    const [actionList, setActionList] = useState([]);
    const [clientAction, setClientAction] = useState(null);


    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const navigate = useNavigate();
    useEffect(() => {
        navigate(`?search=${termSearch.txtSearch}`, { replace: true });
    }, [navigate, termSearch])


    useEffect(() => {
        (async function searchAction() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch?.txtSearch || '',
                }
                const dtResponse = await actionApi.search(params);
                const dtAction = dtResponse?.data['dtAction'];
                setActionList(dtAction);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        })();

    }, [termSearch])

    return { actionList, clientAction, setClientAction, termSearch, setTermSearch, isLoading, message, setMessage };
}