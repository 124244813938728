import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, MLabel, Title, Wrapper } from './styles';
import BackButton from 'components/BackButton';

const ActionItem = ({ item, handleClick }) => {
    return (
        <Wrapper>
            <Content onClick={handleClick}>
                <Title>{item.ClientName || '-'}</Title>
                <Line>
                    <MLabel>現場：</MLabel>
                    {item.SiteName || '―'}
                </Line>
                <Line>
                    <MLabel>担当者：</MLabel>
                    {item.StaffName || '―'}
                </Line>
                <Line>
                    <MLabel>訪問日：</MLabel>
                    {item.ActionDate ? item.ActionDate.slice(0, 10) : '－'}
                </Line>
                <Line>
                    <MLabel>タイトル：</MLabel>
                    {item.ActionTitle || '－'}
                </Line>
            </Content>
        </Wrapper >
    )
}

ActionItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActionItem;
