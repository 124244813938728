import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import salesSlipApi from "../../../api/salesSlipApi";
import HeaderBox from "../../../components/HeaderBox";
import Loading from "../../../components/Loading";
import MessageBox from "../../../components/MessageBox";
import { MESSAGE } from "../../../constants";
import GoodsList from "../components/GoodsList";
import { Content, Info, Line, MLabel, Wrapper } from "./styles";

const SalesSlipDetails = () => {
    const [salesSlip, setSalesSlip] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const { salesSlipID } = useParams();

    useEffect(() => {
        const fetchSalesSlip = async () => {
            try {
                setIsLoading(true);
                const params = {
                    salesSlipID: salesSlipID
                }
                const response = await salesSlipApi.fetch(params);
                setSalesSlip(response.data?.salesSlip || null);
                setIsLoading(false);

            } catch (error) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [error]
                }
                setMessage(errMsg);
                setIsLoading(false);
            }
        }

        fetchSalesSlip();
    }, [salesSlipID]);

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={salesSlip?.ClientName || '伝票明細'} showCart />
            <MessageBox message={message} setMessage={setMessage} />
            {!!salesSlip &&
                <Content>
                    <Info>
                        <Line>
                            <MLabel>受注番号：</MLabel>{salesSlip.SlipCodeAll}{salesSlip.SlipCodeClient}
                        </Line>
                        <Line>
                            <MLabel>受注日：</MLabel>{(salesSlip.AcceptedDate || '--').substring(0, 10)}
                        </Line>
                        <Line>
                            <MLabel>お届け先：</MLabel>{salesSlip.SiteName || '--'}
                        </Line>
                        <Line>
                            <MLabel>納品スタッフ：</MLabel>{salesSlip.DeliveryStaffName || '--'}
                        </Line>
                        <Line>
                            <MLabel>納品日：</MLabel>{(salesSlip.DeliveryDate || '--').substring(0, 10) || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送タイプ：</MLabel>{salesSlip.DeliveryType || '--'}　{salesSlip.DeliveryTypeComment || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送時間：</MLabel>{salesSlip.DeliveryTime || '--'}
                        </Line>
                        <Line>
                            <MLabel>配送状況：</MLabel>{salesSlip.Delivered > 0 ? '済' : '未'}
                        </Line>
                        <Line>
                            <MLabel>配送スタッフ：</MLabel>{salesSlip.DeliveryStaffName || '--'}
                        </Line>
                        <Line>
                            <MLabel>現場の住所：</MLabel>{salesSlip.SiteAddress || '--'}
                        </Line>
                        <Line>
                            <MLabel>メモ：</MLabel>{salesSlip.SalesSlipMemo || '--'}
                        </Line>
                    </Info>
                    <GoodsList goodsList={salesSlip.GoodsList} />
                </Content>
            }
        </Wrapper>
    );
};

export default SalesSlipDetails;
