import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import goodsApi from '../../api/goodsApi';
import { MESSAGE } from '../../constants';

export const useGoods = (isStock) => {

    const location = useLocation();
    const objSearch = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const obj = {
            txtSearch: search.get("search") || '',
        };
        return obj;
    }, [location.search]);

    const [termSearch, setTermSearch] = useState({ txtSearch: objSearch?.txtSearch || '' });
    const [goodsList, setGoodsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const navigate = useNavigate();
    useEffect(() => {
        navigate(`?search=${termSearch.txtSearch}`, { replace: true });
    }, [navigate, termSearch])


    useEffect(() => {

        async function searchGoods() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch?.txtSearch || '',
                    isStock: isStock
                }
                const dtResponse = await goodsApi.search(params);
                const dtGoods = dtResponse?.data['dtGoods'];
                setGoodsList(dtGoods);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        }

        searchGoods();
    }, [termSearch, isStock])

    return { goodsList, termSearch, setTermSearch, isLoading, message, setMessage };
}