import styled from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;
   
`;

export const Content = styled.div`
    flex-grow:1;
    overflow-y:scroll;
    padding-left: 4px;
    padding-right: 4px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: ${prop => (prop?.column ? 'column' : 'row')} ;
    margin-top: 5px;
    .pl-20{
        padding-left: 20px;
    }

    .sub-label{
        padding-left: 8px;
        font-size: 0.8rem;
    }
`;

export const MLabel = styled.label`
    font-size: 0.9rem;
    color: #6c757d;
`

export const Title = styled.span`
    color:var(--morienGreen);
    font-size: 1rem;
    font-weight:bold;
`
export const Line = styled.div`
`

export const InputText = styled.input.attrs({ type: 'text' })`
    font-size: 1rem;
    margin-left: 20px;
    margin-right: 10px;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    color: #000;
`

export const InputDate = styled.input.attrs({ type: 'date' })`
    width: 130px;    
    margin-left: 20px;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    font-size: 1rem;
    color: #000;
`

export const TextArea = styled.textarea`
    margin-left: 20px;
    margin-right: 10px;
    font-size: 1rem;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    resize: none;
`;

export const Select = styled.select`
    max-width: 250px;    
    min-width: 100px;
    margin-left: 20px;
    font-size: 1rem;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    color: #000;
    margin-top: auto;
    margin-bottom: auto;
`

export const Option = styled.option`
    
`

export const Button = styled.button`
    margin-left:3px;
    padding: 2px 10px;
    font-size: 1rem;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 5px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;

    button{
        padding: 5px 20px;
        font-size: 1rem;
        font-weight: bold;
    }

    .back {
        background-color: #7c7c7c;
        border-color: #7c7c7c;
    }
`;