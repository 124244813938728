import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, MLabel, Title, Wrapper } from './styles';

const SiteItem = ({ item }) => {

    return (
        <Wrapper>
            <Content>
                <Title >{item.SiteName}</Title>
                <Line>
                    〒{item.PostalCode}　{item.AddressPrefecture || ''}{item.AddressCity || ''}{item.AddressNumber || ''}
                </Line>
                <Line>
                    <MLabel>お客様：</MLabel>{item.ClientName || ''}
                </Line>
                <Line>
                    <MLabel>開始日：</MLabel>{item.DateBeginning ? item.DateBeginning.slice(0, 10) : '－'}
                    &emsp;
                    <MLabel>最後：</MLabel>{item.DateFinished ? item.DateFinished.slice(0, 10) : '－'}
                </Line>
            </Content>
        </Wrapper>
    )
}

SiteItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default SiteItem;
