import axiosClient from "./axiosClient";

const stockGoodsApi = {
    getInfoLast(params) {
        const url = '/stock-goods-last';
        return axiosClient.post(url, params);
    },

}

export default stockGoodsApi;
