import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
`;

export const SHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 10px;
    background-color:#d3e8d8;
    font-size: 16px;
    font-weight:600;
    
    .btn {
        padding:2px 10px;
        background-color: var(--morienGreen);
        border: 1px solid var(--morienGreen);
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight:600;
        text-decoration: none;
    }
`;

export const STable = styled.div`
    display:flex;
    justify-content: center;
    margin: 5px auto;
    text-align: center;
    
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px 10px;
    }
   
    th{
        background-color: #f0f0f0;
    }
    .align-right{
        text-align: right;
    }
`;