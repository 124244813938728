import React, { createRef, useEffect } from 'react';
import HeaderBox from 'components/HeaderBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import ClientManagementItem from './components/ClientManagementItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useClientManagement } from './useClientManagement';

const ClientManagement = () => {
    const { clientList, termSearch, setTermSearch, isLoading, message, setMessage } = useClientManagement();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [clientList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='お客様（担当のみ）' showCart />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='お客様名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {clientList?.length > 0 ? (
                    clientList.map((item) => {
                        return (<ClientManagementItem key={item.ClientID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
        </Wrapper>
    )
}

export default ClientManagement;