import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import siteApi from "api/siteApi";
import { MESSAGE } from "constants";

export const useSiteNew = () => {
    const [siteInfo, setSiteInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isFirstRun = useRef(true);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });


    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        (async function registerSite() {
            try {
                //validate
                if (!siteInfo?.siteName || !siteInfo?.dateBeginning || !siteInfo?.dateFinished || siteInfo?.clientID < 6) return;
                setIsLoading(true);
                console.log(siteInfo);

                const dtResponse = await siteApi.register(siteInfo);
                console.log(dtResponse);

                //TODO: New Site
                const newSiteID = dtResponse?.data['siteID'] ?? '';
                if (newSiteID < 1) {
                    throw Error("登録が失敗しました。");
                }

                alert("登録が成功しました。");
                navigate(`/site`, { replace: true });
            } catch (err) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);

            } finally {
                setIsLoading(false);
            }
        })();

    }, [siteInfo])

    return { setSiteInfo, isLoading, setIsLoading, message, setMessage };

}