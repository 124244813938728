import React, { useState } from 'react'
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BtnClose, ButtonDiv, CheckBox, Header, OverLay, SelectedList, SubTitle, Info, Title, Wrapper, TextArea, BtnMemo } from './styles';
import CartItem from './CartItem';
import { Link } from 'react-router-dom';
import { LocalStorageClearMemo, LocalStorageGetIsStock, LocalStorageGetMemo, LocalStorageSaveMemo } from 'utils';
import { ORDER_TYPE } from 'constants';


const HeaderCart = ({ idCart, cart }) => {
    const [tempMemo, setTempMemo] = useState(LocalStorageGetMemo());
    const [openTextAria, setOpenTextAria] = useState(!!tempMemo);

    const hanleClickOpenMemo = () => {
        if (openTextAria) LocalStorageClearMemo();
        setOpenTextAria(!openTextAria);

    }
    const isStock = LocalStorageGetIsStock();

    return (
        <>
            <CheckBox id={idCart} hidden />
            <OverLay htmlFor={idCart} />
            <Wrapper>
                <Header>
                    <Info>
                        <Title>{cart?.clientName || '未入力'}</Title>
                        <SubTitle>{cart?.siteName || '未入力'}</SubTitle>
                    </Info>
                    <BtnClose htmlFor={idCart} ><FontAwesomeIcon icon={faXmark} /></BtnClose>
                </Header>
                <SelectedList>
                    {cart.goodsList?.length > 0 ?
                        <>
                            {cart.goodsList.map((item, index) => {
                                return (<CartItem key={index} item={item} />)
                            })}
                            <div style={{ textAlign: "center" }}>
                                {!!openTextAria && (<TextArea value={tempMemo}
                                    rows={6}
                                    onChange={(e) => {
                                        LocalStorageSaveMemo(e.target.value);
                                        setTempMemo();
                                    }}
                                />)}
                            </div>
                            <div style={{ textAlign: "right", paddingRight: 4 }}>
                                <BtnMemo onClick={() => { hanleClickOpenMemo(); }}>{!openTextAria ? "メモ" : "削除"}</BtnMemo>
                            </div>
                            {!isStock && (<ButtonDiv>
                                <Link className='ButtonConfirm' to={'/cart'} state={{ type: ORDER_TYPE.CHUMON }}>注文へ進む</Link>
                            </ButtonDiv>)}
                            {!!isStock && (<ButtonDiv>
                                <Link className='ButtonConfirm BtnHacchu' to={'/cart'} state={{ type: ORDER_TYPE.HACCHU }}>在庫発注へ進む</Link>
                            </ButtonDiv>)}
                        </>
                        : <></>}

                </SelectedList>
            </Wrapper>
        </>
    )
}


HeaderCart.propTypes = {
    idCart: PropTypes.string,
}

HeaderCart.defaultProps = {
    idCart: 'id_cart',
}

export default HeaderCart;