import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute, RestrictedRoute } from './Router';
import { Wrapper } from './App.styles';
import Login from './pages/Login';
import { BASE_NAME } from './constants';
import Client from './pages/Client';
import Site from './pages/Site';
import Goods from './pages/Goods';
import SalesSlip from './pages/SalesSlip';
import Cart from './pages/Cart';
import GoodsSub from './pages/GoodsSub';
import SalesSlipDetails from './pages/SalesSlip/Details';
import Menu from './pages/Menu';
import GoodsDetails from './pages/GoodsDetails';
import ClientActionRegister from './pages/ClientAction/Register';
import ClientAction from 'pages/ClientAction';
import SiteNew from 'pages/Site/SiteNew';
import Company from 'pages/Company';
import ClientManagement from 'pages/ClientManagement';
import GoodsStock from 'pages/GoodsStock';


function App() {

    return (
        <Wrapper>
            <BrowserRouter basename={BASE_NAME}>
                <Routes>
                    <Route element={<RestrictedRoute />}>
                        <Route path='/login' element={<Login />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        {/* お客様一覧 */}
                        <Route path='/client' element={<Client />} />

                        {/* お客様一覧 */}
                        <Route path='/client-management' element={<ClientManagement />} />
                        {/* 伝票一覧 */}
                        <Route path='/sales-slip' element={<SalesSlip />} />
                        <Route path='/sales-slip/:salesSlipID' element={<SalesSlipDetails />} />

                        {/* 現場一覧 */}
                        <Route path='/site' element={<Site />} />
                        {/* 現場登録 */}
                        <Route path='/site-new' element={<SiteNew />} />


                        {/* 商品一覧（伝票新規登録） */}
                        <Route path='/' element={<Menu />} />
                        <Route path='/goods' element={<Goods isStock={0} />} />
                        <Route path='/goods-stock' element={<GoodsStock />} />

                        {/* 商品明細*/}
                        <Route path='/goods/:id' element={<GoodsDetails />} />

                        {/* 商品サブ一覧（伝票新規登録） */}
                        <Route path='goods/:id/sub' element={<GoodsSub />} />
                        {/* 伝票履歴*/}
                        {/* <Route path='/sales-slip' element={<SalesSlip />} /> */}
                        {/* カート（選択した商品一覧）*/}
                        <Route path='/cart' element={<Cart />} />
                        <Route path='/action' element={<ClientActionRegister />} />
                        <Route path='/action-list' element={<ClientAction />} />
                        <Route path='/company' element={<Company />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Wrapper>
    );
}

export default App;