import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, Content, Title, Quantity, Number, TitleChild, BtnUpDown, BtnDelete, MLabel, InputDate, BoxMemo, InputMemo, WrappHacchu, WrappStock, MLabelStock, MTitleStock } from './styles';
import { addGoods, removeGoods } from 'redux/cartSlice';

const CartItem = ({ item, stockGoods, isHacchu }) => {

    const dispatch = useDispatch();
    const handleUpDown = (num) => {
        const goods = { ...item, quantity: num };
        dispatch(addGoods(goods));
    }

    const handleRemoveGoods = () => {
        const goods = { ...item };
        dispatch(removeGoods(goods));
    }
    const handleChangeArrivalDate = (e) => {
        const goods = { ...item, quantity: 0, arrivalDate: e.target.value };
        dispatch(addGoods(goods));
    }
    const handleChangeOrderTempMemo = (e) => {
        const goods = { ...item, quantity: 0, orderTempMemo: e.target.value };
        dispatch(addGoods(goods));
    }


    return (
        <Wrapper>
            <Title>
                {item.goodsName}
                <TitleChild>　¦ {item?.goodsSize}</TitleChild>
            </Title>
            <Content>
                <Quantity>
                    <BtnUpDown onClick={() => { if (item.quantity > 1) handleUpDown(-1); }} ><FontAwesomeIcon icon={faMinus} /></BtnUpDown>
                    <Number>{item.quantity}</Number>
                    <BtnUpDown onClick={() => { handleUpDown(+1); }}><FontAwesomeIcon icon={faPlus} /></BtnUpDown>
                    {!!isHacchu && (<div style={{ marginLeft: "8px" }}>
                        <MLabel>最低：{stockGoods?.NumberMinimum || "-"}  発注：{stockGoods?.NumberToOrder || "-"}</MLabel>
                    </div>)}

                </Quantity>

                {!!isHacchu && !!item.orderMemo && (<BoxMemo>{item.orderMemo}</BoxMemo>)}
                <BtnDelete onClick={() => { handleRemoveGoods() }}>削除</BtnDelete>

            </Content>
            {!!isHacchu && (<WrappHacchu>
                <Content>
                    <MLabel>入荷予定日：</MLabel>
                    <InputDate
                        value={item.arrivalDate}
                        onChange={handleChangeArrivalDate}
                    />
                    <InputMemo
                        placeholder='一時メモ'
                        value={item.orderTempMemo}
                        onChange={handleChangeOrderTempMemo} />
                </Content>
                {!!stockGoods.ReceivedNumber && (
                    <>
                        <MTitleStock>最近在庫状況</MTitleStock>
                        <WrappStock>
                            <div>
                                <MLabelStock >入荷数：</MLabelStock> {stockGoods.ReceivedNumber}
                            </div>
                            <div>
                                <MLabelStock >発注日：</MLabelStock>{stockGoods.OrderedDate.substring(0, 10) || '--'}
                            </div>
                            <div>
                                <MLabelStock >発注者：</MLabelStock>{stockGoods.OrderedStaffName}
                            </div>
                        </WrappStock></>)}
            </WrappHacchu>)}
        </Wrapper>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default CartItem