import axiosClient from "./axiosClient";

const orderApi = {
    save(params) {
        const url = '/order';
        return axiosClient.post(url, params);
    }
}

export default orderApi;
