import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color:beige;
    
`;

export const InputBox = styled.form`
    display:flex;
    width: 100%;
    justify-content: center;    
    padding: 5px 0;
    input{
        width: 70%;
        max-width: 500px;
        border: 2px solid #c6c6c6;
        border-radius: 5px;
        font-size: 1rem;
        padding: 5px;
        @media screen and (max-width: 768px){
        }
    }
    
    button{
        margin-left:3px;
        padding: 0 13px;
        font-size: 1rem;
        border:1px solid transparent;
        border-color: var(--morienGreen);
        border-radius: 5px;
        background-color: var(--morienGreen);
        color: white;
        cursor: pointer;
        :active{
            background-color: #32cd32;            
        }
    }

`;