import React, { useState } from "react";
import Loading from "../../components/Loading";
import { useLogin } from "./useLogin";
import ImgLogo from '../../assets/images/logo.png'
import { Wrapper, Content, Logo } from './styles'
import MessageBox from "../../components/MessageBox";
import { MESSAGE } from "../../constants";

const Login = () => {
    const { setUseInput, isLoading, message, setMessage } = useLogin();
    const [userID, setUserID] = useState('');
    const [uPassword, setUPassword] = useState('');
    //Validate User Info
    const validateInfo = () => {
        if (!userID || !uPassword) {
            const errMsg = {
                type: MESSAGE.ERROR,
                contents: ['ユーザーIDやパスワードを必須入力とする']
            }
            setMessage(errMsg)
            return false;
        }
        return true;
    }

    //Submit User Info
    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (validateInfo()) {
            setUseInput({
                userID: userID,
                password: uPassword
            })
        }
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <MessageBox message={message} setMessage={setMessage} />
            <Content onSubmit={handleOnSubmit}>
                <Logo src={ImgLogo} alt='Logo' />
                <input type='text'
                    placeholder='ユーザーID'
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />
                <input type='password'
                    placeholder='パスワード'
                    autoComplete='on'
                    value={uPassword}
                    onChange={(e) => setUPassword(e.target.value)}
                />
                <button type='submit'>ログイン</button>
            </Content>
        </Wrapper>
    );
};

export default Login;
