import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import siteApi from '../../api/siteApi';
import { MESSAGE } from '../../constants';


export const useSite = () => {
    const { clientID, clientName } = useSelector((state) => ({ clientID: state.cart.clientID, clientName: state.cart.clientName }));
    const location = useLocation();
    const objSearch = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const obj = {
            txtSearch: search.get("search") || '',
        };
        return obj;
    }, [location.search]);

    const [termSearch, setTermSearch] = useState({ txtSearch: objSearch?.txtSearch || '' });
    const [siteList, setSiteList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const navigate = useNavigate();
    useEffect(() => {
        navigate(`/site?search=${termSearch.txtSearch}`, { replace: true });
    }, [navigate, termSearch])


    useEffect(() => {

        async function searchSite() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch?.txtSearch || '',
                    clientID: clientID
                }
                const dtResponse = await siteApi.search(params);
                const dtSite = dtResponse?.data['dtSite'];
                setSiteList(dtSite);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        }

        searchSite();
    }, [termSearch, clientID])

    return { clientID, clientName, siteList, termSearch, setTermSearch, isLoading, message, setMessage };
}