import { createSlice } from '@reduxjs/toolkit'
import { LocalStorageClearCart, LocalStorageGetCart, LocalStorageSaveCart } from '../utils';

const initialCart = LocalStorageGetCart();

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialCart,
    reducers: {
        addGoods: (state, actions) => {
            const goods = actions.payload;

            const goodsList = state?.goodsList || [];
            if (goodsList?.length < 1) {
                //Add new goods
                state.goodsList = [goods];
            } else {
                //Find goods in cart
                const index = goodsList.findIndex((item) => {
                    return (item.goodsName === goods.goodsName) && (item.goodsID === goods.goodsID);
                })
                if (index < 0) {
                    //Add new goods
                    state.goodsList = [...goodsList, goods];
                } else {
                    //Old Goods: update quantity and arrivalDate
                    state.goodsList[index].quantity = Number(state.goodsList[index].quantity) + Number(goods.quantity);
                    state.goodsList[index].arrivalDate = goods.arrivalDate;
                    state.goodsList[index].orderTempMemo = goods.orderTempMemo || '';
                }
            }
            LocalStorageSaveCart(state);
        },
        removeGoods: (state, actions) => {
            const goods = actions.payload;
            const goodsList = state?.goodsList || [];
            //find Goods in Cart
            const index = goodsList.findIndex((item) => {
                return (item.goodsName === goods.goodsName) && (item.goodsID === goods.goodsID);
            })

            if (index >= 0) {
                //remove goods from Cart
                state.goodsList.splice(index, 1);
            }

            //save cart to localstorage
            LocalStorageSaveCart(state);

        },

        // お客様
        setClient: (state, actions) => {
            const client = actions.payload;
            //Refresh site
            if (state.clientID !== client.clientID) {
                state.siteID = 1;
                state.siteName = '未入力';
            }
            state.clientID = client.clientID;
            state.clientName = client.clientName;

            //save cart to localstorage
            LocalStorageSaveCart(state);
        },
        removeClient: (state) => {
            state.clientID = 1;
            state.clientName = '未入力';
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },

        // 拠点
        setBase: (state, actions) => {
            const _baseID = actions.payload;
            state.baseID = _baseID;
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },
        removeBase: (state) => {
            state.baseID = 1;
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },

        // 配送タイプ
        setDeliveryTypeID: (state, actions) => {
            const _deliveryTypeID = actions.payload;
            state.deliveryTypeID = _deliveryTypeID;
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },
        removeDeliveryTypeID: (state) => {
            state.deliveryTypeID = 1;
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },

        // 現場
        setSite: (state, actions) => {
            const site = actions.payload;
            state.siteID = site.siteID;
            state.siteName = site.siteName;
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },

        removeSite: (state) => {
            state.siteID = 1;
            state.siteName = '未入力';
            //save cart to localstorage
            LocalStorageSaveCart(state);
        },

        // カート削除
        clearCart: (state) => {
            LocalStorageClearCart();
            return {};
        },
    },
})

// Action creators are generated for each case reducer function
export const { addGoods, removeGoods, setClient, removeClient, setBase, removeBase, setDeliveryTypeID, removeDeliveryTypeID, setSite, removeSite, clearCart } = cartSlice.actions;

export default cartSlice.reducer