import React from 'react';

import { Wrapper } from './styles';

const TwitterGoodsItem = ({ twitterGoodsItem }) => {
    return (
        <Wrapper>
            <div className='item-title'>
                <span>{twitterGoodsItem.ClientName}</span>
                {/* <span className='pl-30'>{twitterGoodsItem.ClientName}</span> */}
            </div>
            <div>
                <label>Twitter：</label>
                <div className='content-twitter'>
                    {twitterGoodsItem.Twitter}
                </div>
            </div>
            <div>
                <label>メモ：</label>
                <div className='content-twitter' >
                    {twitterGoodsItem.Memo || '-'}
                </div>
            </div>
        </Wrapper >
    )
}

export default TwitterGoodsItem
