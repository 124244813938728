import styled from 'styled-components';
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ececec;   
`;
export const Content = styled(Link)`
    flex: 4;
    display:flex;
    flex-direction: column;
    
    padding: 5px 5px;
    color: black;
    text-decoration:none;
    .ml-30 {
        margin-left: 30px;
    }

    :hover{
        background-color: #ececec;
        cursor:pointer;
    }
`
export const Title = styled.span`
    color:var(--morienGreen);
    font-weight:bold;
`
export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`

export const RightControl = styled.div`
    flex: 1;
    max-width: 100px;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
`


export const Button = styled.button`
    padding: 3px 5px;
    font-size: 1rem;
    outline: none;
    border:1px solid transparent;
    border-color: var(--morienGreen);
    border-radius: 3px;
    background-color: var(--morienGreen);
    color: white;
    cursor: pointer;
    :active{
        background-color: #32cd32;            
    }
`