import axiosClient from "./axiosClient";

const goodsApi = {
    search(params) {
        const url = '/goods';
        return axiosClient.get(url, { params: params });
    },
    getDetails(params) {
        const url = '/goods-details';
        return axiosClient.get(url, { params: params });
    },
}

export default goodsApi;
