import axios from 'axios';
import { API_BASE_URL, URL_LOGIN } from '../constants';
import { LocalStorageClearAll, LocalStorageGetToken } from '../utils';

const axiosClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

// Interceptors
// Add a request interceptor
axiosClient.interceptors.request.use(
    function (config) {
        const { stUserID, stToken } = LocalStorageGetToken();
        config.headers['ST-User'] = stUserID;
        config.headers['ST-Token'] = stToken;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        //TODO: Catch Error
        console.log('エラー：' + error.message);
        console.log(error.response?.status === 1);
        if (error.response?.status === 401) {
            LocalStorageClearAll();
            window.location.replace(URL_LOGIN);
        }
        return Promise.reject(error);
    }
);

export default axiosClient
