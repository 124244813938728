import React, { createRef, useEffect } from 'react';
import BackButton from '../../components/BackButton';
import HeaderBox from '../../components/HeaderBox';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import GoodsSubItem from './components/GoodsSubItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useGoodsSub } from './useGoodsSub';

const GoodsSub = () => {
    const { goodsSubList, termSearch, setTermSearch, isLoading, message, setMessage } = useGoodsSub();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [goodsSubList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='色選択' showCart={true} />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='色名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {goodsSubList?.length > 0 ? (
                    goodsSubList.map((item) => {
                        return (<GoodsSubItem key={item.GoodsSubID} item={item} setMessage={setMessage} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
            <BackButton />
        </Wrapper>
    )
}

export default GoodsSub