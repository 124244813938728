import React from 'react';

import { Wrapper } from './styles';

const SalesGoodsItem = ({ salesGoodsItem }) => {
    return (
        <Wrapper>
            <div className='item-title'>
                <span>{salesGoodsItem.SalesSlipID}</span>
                <span className='pl-30'>{salesGoodsItem.ClientName}</span>
            </div>
            <div>
                <span><label>支払：</label>{salesGoodsItem.SalesPaymentType}</span>
                <span className='pl-30'><label>売上日：</label>{salesGoodsItem.SalesDate.substring(0, 10) || '--'}</span>
            </div>
            <div>
                <span><label>品名：</label>{salesGoodsItem.GoodsName}</span>
            </div>
            <div>
                <span><label>サイズ：</label>{salesGoodsItem.GoodsSize}</span>
                <span className='pl-30'><label>数量：</label>{salesGoodsItem.Number}</span>
            </div>
        </Wrapper>
    )
}

export default SalesGoodsItem
