import React, { useState } from "react";
import JapanPostalCode from "japan-postal-code";

import HeaderBox from "components/HeaderBox";
import Loading from "components/Loading";
import { Wrapper, Content, Row, MLabel, InputText, Line, Select, InputDate, TextArea, Button, Footer, InputNumber, Title } from './styles'
import { useSiteNew } from "./useSiteNew";
import InputSelectBox from "components/InputSelectBox";
import { useClientBox } from "hooks/useClientBox";
import MessageBox from "components/MessageBox";
import { MESSAGE } from "constants";

const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const renderClientItem = (item) => {
    return (
        <>
            <Title>{item?.ClientName || '-'}</Title>
            < Line >
                <MLabel>住所：</MLabel>
                〒{item?.PostalCode}　{item?.Pref || ''}{item?.Adr || ''}
            </Line>
        </>)
}

const SiteType = [
    { SiteTypeID: 1, SiteType: "一般現場" },
    { SiteTypeID: 2, SiteType: "御店" },
    { SiteTypeID: 3, SiteType: "倉庫" },
]

const SiteNew = () => {
    const [clientID, setClientID] = useState(5);
    const [clientName, setClientName] = useState("");
    const [siteName, setSiteName] = useState('');
    const [siteKana, setSiteKana] = useState('');
    const [siteTypeID, setSiteTypeID] = useState(1);
    const [dateBeginning, setDateBeginning] = useState(dNowStr);
    const [dateFinished, setDateFinished] = useState(dNowStr);
    const [postalCode, setPostalCode] = useState('');
    const [addressPref, setAddressPref] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressNumber, setAddressNumber] = useState('');
    const [tel, setTel] = useState('');
    const [area, setArea] = useState(0);
    const [siteMemo, setSiteMemo] = useState('');
    const { setSiteInfo, isLoading, setIsLoading, message, setMessage } = useSiteNew();

    const { clientList, termClient, setTermClient } = useClientBox({ setIsLoading: setIsLoading, setMessage: setMessage });
    //お客様選択    

    const selectClient = (client) => {
        setClientName(client.ClientName);
        setClientID(client.ClientID);
    }

    const validate = () => {
        let msg = '';
        const dateStart = new Date(dateBeginning);
        const dateEnd = new Date(dateFinished);

        //Check ClientID 
        if (clientID < 6) {
            msg = 'お客様を選択してください。';
        } else if (!siteName.trim()) {
            //SiteName
            msg = '現場名は入力必須項目です。';
        } else if (dateStart >= dateEnd) {
            msg = '期間指定開始日または最後日の日付範囲が正しくありません';
        }

        if (msg) {
            const errMsg = {
                type: MESSAGE.ERROR,
                contents: [msg]
            }
            setMessage(errMsg);
            return false;
        }
        return true;
    }


    const handlePostCode = () => {
        if (!postalCode) return;
        JapanPostalCode.get(postalCode, (addr) => {
            setAddressPref(addr.prefecture);
            setAddressCity(addr.city + addr.area);
        })

    }

    const handleRegister = () => {
        if (validate()) {
            setSiteInfo({
                clientID,
                siteName,
                siteKana,
                siteTypeID,
                dateBeginning,
                dateFinished,
                postalCode,
                addressPref,
                addressCity,
                addressNumber,
                tel,
                area,
                siteMemo,
            })
        }
    }


    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title="現場登録" showCart={true} />
            <MessageBox message={message} setMessage={setMessage} />
            <Content>
                <Row column>
                    <MLabel>お客様：</MLabel>
                    <InputSelectBox title='お客様を選択してください'
                        name={clientName || '未入力'}
                        textHolder={'お客様名'}
                        termSearch={termClient}
                        setTermSearch={setTermClient}
                        resultList={clientList}
                        renderItem={renderClientItem}
                        selectItem={selectClient}
                        classNameCss={"ml-12"}
                    />
                </Row>
                <Row column>
                    <MLabel>現場名：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'現場名'}
                        value={siteName}
                        onChange={(e) => { setSiteName(e.target.value) }}
                    />
                </Row>
                <Row column>
                    <MLabel>カナ：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'カナ'}
                        value={siteKana}
                        onChange={(e) => { setSiteKana(e.target.value) }}
                    />

                </Row>
                <Row column>
                    <MLabel>現場タイプ：</MLabel>
                    <Select
                        value={siteTypeID}
                        onChange={(e) => {
                            setSiteTypeID(e.target.value);
                        }}
                    >
                        {SiteType.map((item) => {
                            return (<option key={item.SiteTypeID} value={item.SiteTypeID}>{item.SiteType}</option>)
                        })}
                    </Select>
                </Row>
                <Row column>
                    <MLabel>開始日～最後日：</MLabel>
                    <Line>
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateBeginning}
                            onChange={(e) => { setDateBeginning(e.target.value); }}
                        />
                        ～
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateFinished}
                            onChange={(e) => { setDateFinished(e.target.value); }}
                        />
                    </Line>
                </Row>
                <Row column>
                    <MLabel>郵便番号：</MLabel>
                    <Line>
                        <InputText
                            width={'100px'}
                            maxLength={10}
                            value={postalCode}
                            onChange={(e) => { setPostalCode(e.target.value); }}
                        />
                        <Button onClick={handlePostCode}>住所入力</Button>
                    </Line>
                </Row>
                <Row column>
                    <MLabel>都府県：</MLabel>
                    <InputText
                        width={'150px'}
                        maxLength={5}
                        value={addressPref}
                        onChange={(e) => { setAddressPref(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>住所：</MLabel>
                    <InputText
                        maxLength={100}
                        value={addressCity}
                        onChange={(e) => { setAddressCity(e.target.value); }}
                    />
                    <InputText
                        mtop={'4px'}
                        maxLength={100}
                        value={addressNumber}
                        onChange={(e) => { setAddressNumber(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>ＴＥＬ：</MLabel>
                    <InputText
                        width={'150px'}
                        maxLength={50}
                        value={tel}
                        onChange={(e) => { setTel(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>面積：</MLabel>
                    <InputNumber
                        width={'100px'}
                        maxLength={10}
                        value={area}
                        onChange={(e) => { setArea(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>メモ(現場詳細、配達の際の注意事項があればご記入下さい)：</MLabel>
                    <TextArea
                        maxLength={300}
                        rows={4}
                        value={siteMemo}
                        onChange={(e) => { setSiteMemo(e.target.value); }}
                    />
                </Row>
                <Footer>
                    <Button onClick={() => { handleRegister(); }}>登録</Button>
                </Footer>
            </Content>
        </Wrapper>
    )
}

export default SiteNew;