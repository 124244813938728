import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import actionApi from 'api/actionApi';
import HeaderBox from 'components/HeaderBox';
import InputSelectBox from 'components/InputSelectBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import { MESSAGE } from 'constants';
import { useClientBox } from 'hooks/useClientBox';
import { useSiteBox } from 'hooks/useSiteBox';
import { LocalStorageGetStaff } from 'utils';
import { Button, Content, Footer, InputDate, InputText, Line, MLabel, Option, Row, Select, TextArea, Title, Wrapper } from './styles';

const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const ClientInfoType = [
  "問い合わせ内容",
  "商品・キャンペーン提案",
  "特別な対応　急な配達含める",
  "クレーム",
  "ライバル情報",
  "ご満足の声",
  "契約",
  "支払い",
  "現場調査",
  "色合わせ・既存色確認",
  "その他",
]

const ActionType = [
  { ClientActionTypeID: 1, ClientActionType: "訪問" },
  { ClientActionTypeID: 11, ClientActionType: "納品" },
  { ClientActionTypeID: 21, ClientActionType: "来店" },
  { ClientActionTypeID: 101, ClientActionType: "携帯TEL" },
  { ClientActionTypeID: 111, ClientActionType: "店頭TEL" },
]

const ActionSchedule = [
  "週1回",
  "週2回",
  "週3回以上",
  "月1回",
  "月2回",
  "月3回以上",
  "毎日",
]

//現場選択
const RenderSiteItem = (item) => {
  return (
    <>
      <Title >{item.SiteName}</Title>
      <Line>
        <MLabel>住所：</MLabel>
        〒{item.PostalCode}　{item.AddressPrefecture || ''}{item.AddressCity || ''}{item.AddressNumber || ''}
      </Line>
      <Line>
        <MLabel>日付：</MLabel>{item.DateBeginning ? item.DateBeginning.slice(0, 10) : '－'}～{item.DateFinished ? item.DateFinished.slice(0, 10) : '－'}
      </Line>
    </>)
}

//お客様選択    
const RenderClientItem = (item) => {
  return (
    <>
      <Title>{item?.ClientName || '-'}</Title>
      < Line >
        <MLabel>住所：</MLabel>
        〒{item?.PostalCode}　{item?.Pref || ''}{item?.Adr || ''}
      </Line>
    </>)
}

const staff = LocalStorageGetStaff();

const ClientActionRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(
    {
      title: '',
      type: '',
      contents: []
    }
  );
  const [client, setClient] = useState({
    ClientID: location?.state?.ClientID || 1,
    ClientName: location?.state?.ClientName || ""
  });

  const [site, setSite] = useState({ SiteID: 1, SiteName: '未入力' });
  const [actionDate, setActionDate] = useState(dNowStr);
  const [clientInfo, setClientInfo] = useState("");
  const [actionMemo, setActionMemo] = useState("");
  const [actionTypeID, setActionTypeID] = useState(location?.state?.ClientActionTypeID || 1);
  const [rivalInfo, setRivalInfo] = useState("");
  const [actionSchedule, setActionSchedule] = useState(ActionSchedule[0]);
  const [actionTitle, setActionTitle] = useState("");
  const [clientInfoType, setClientInfoType] = useState(0);
  const [isAlt, setIsAlt] = useState(1);
  const { clientList, termClient, setTermClient } = useClientBox({ setIsLoading: setIsLoading, setMessage: setMessage, noneCredit: true });
  const { siteList, termSite, setTermSite } = useSiteBox({ setIsLoading: setIsLoading, setMessage: setMessage });


  const handleSetTermSite = (_termSite) => {
    setTermSite({ ..._termSite, clientID: client.ClientID });
  }

  const handleSubmit = async () => {
    if (!client?.ClientID || !(client?.ClientID > 5) || !actionTypeID || !actionDate) {
      setMessage({
        type: MESSAGE.WARNING,
        contents: ["「お客様」・「区分」・「日付」項目が必須です"]
      })
      return;
    }

    try {
      setIsLoading(true);
      const params = {
        clientID: client.ClientID,
        actionTypeID: actionTypeID,
        siteID: site?.SiteID || 1,
        actionDate,
        actionSchedule,
        actionTitle,
        clientInfo,
        clientInfoType: ClientInfoType[clientInfoType],
        rivalInfo,
        actionMemo,
        isAlt,
      }
      //call api
      const { data } = await actionApi.register(params);

      if (data?.actionID) {
        setMessage({
          type: MESSAGE.INFO,
          contents: ["訪問登録が完成しました"],
          handleClose: () => { navigate("/"); },
        });
      }
    } catch (error) {
      setMessage({
        type: MESSAGE.ERROR,
        contents: ["エラーが発生しました"]
      })
    }
    finally {
      setIsLoading(false);
    }
  }
  return <Wrapper>
    <HeaderBox title={"訪問実績登録"} />
    <Loading isLoading={isLoading} />
    <MessageBox message={message} setMessage={setMessage} />
    <Content>
      <Row column>
        <MLabel >お客様：</MLabel>
        <InputSelectBox title='お客様を選択してください'
          name={client?.ClientName || '未入力'}
          textHolder={'お客様名'}
          termSearch={termClient}
          setTermSearch={setTermClient}
          resultList={clientList}
          renderItem={RenderClientItem}
          selectItem={(_client) => { setClient({ ..._client }); }}
          classNameCss={'pl-20'}
        />
      </Row>
      <Row column>
        <MLabel >現場：</MLabel>
        <InputSelectBox title='現場を選択してください'
          name={site?.SiteName || '未入力'}
          textHolder={'現場名'}
          termSearch={termSite}
          setTermSearch={handleSetTermSite}
          resultList={siteList}
          renderItem={RenderSiteItem}
          selectItem={(_site) => { setSite({ ..._site }); }}
          classNameCss={'pl-20'}
        />

      </Row>
      <Row column>
        <MLabel >訪問計画：</MLabel>
        <Row>
          <Select
            value={actionSchedule}
            onChange={(e) => { setActionSchedule(e.target.value); }}
          >
            {ActionSchedule.map((item, i) => (<Option key={i} value={item}>{item}</Option>))}
          </Select>
        </Row>
      </Row>
      <Row column>
        <MLabel >訪問タイプ：</MLabel>
        <Row>
          <Select
            value={actionTypeID}
            onChange={(e) => { setActionTypeID(e.target.value); }}
          >
            {ActionType.map((type) => (<Option key={type.ClientActionTypeID} value={type.ClientActionTypeID}>{type.ClientActionType}</Option>))}
          </Select>
        </Row>
      </Row>
      <Row column>
        <MLabel >訪問者：</MLabel>
        <Row>
          <InputText value={staff.staffName} readOnly />
          <Select
            value={isAlt}
            onChange={(e) => { setIsAlt(e.target.value); }}
            style={{ marginLeft: 0 }}
          >
            <Option value={1}>メイン</Option>
            <Option value={2}>サブ</Option>
          </Select>
        </Row>
      </Row>
      <Row column>
        <MLabel >訪問日時：</MLabel>
        <InputDate
          value={actionDate}
          onChange={(e) => { setActionDate(e.target.value); }}
        />
      </Row>
      <Row column>
        <MLabel >タイトル：</MLabel>
        <InputText
          value={actionTitle}
          maxLength={100}
          onChange={(e) => { setActionTitle(e.target.value); }}
        />
      </Row>
      <Row column>
        <MLabel >お客様の声：</MLabel>
        <Select
          value={clientInfoType}
          onChange={(e) => { setClientInfoType(e.target.value) }}
          style={{ marginBottom: 4 }}
        >
          {ClientInfoType.map((item, index) => {
            return (<Option key={index} value={index}>{item}</Option>)
          })}
        </Select>
        <TextArea
          maxLength={1000}
          rows={4}
          value={clientInfo}
          onChange={(e) => { setClientInfo(e.target.value) }} />
      </Row>
      <Row column>
        <MLabel >ライバル情報:</MLabel>
        <TextArea
          maxLength={1000}
          rows={4}
          value={rivalInfo}
          onChange={(e) => { setRivalInfo(e.target.value) }} />
      </Row>
      <Row column>
        <MLabel >自分・スタッフの考え:</MLabel>
        <TextArea
          maxLength={1000}
          rows={4}
          value={actionMemo}
          onChange={(e) => { setActionMemo(e.target.value) }} />
      </Row>
      <Footer>
        <Button className='back' onClick={() => { navigate("/"); }}>戻る</Button>
        <Button onClick={() => { handleSubmit(); }} >登録</Button>
      </Footer>
    </Content>
  </Wrapper>;
};

export default ClientActionRegister;
