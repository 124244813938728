import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 3px;
    padding: 3px 5px;
    border-bottom: 1px solid lightgray;
`;

export const Title = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

export const TitleChild = styled.span`
    font-weight: normal;
    color: gray;   

`;

export const Content = styled.div`
    display: flex;
    margin-top: 5px;
    align-items:center;
   
`;

export const WrappHacchu = styled.div`
   
`;

export const WrappStock = styled.div`
    padding: 4px;
    border: solid 1px #ececec;
    font-size:0.7rem;
`;
export const MTitleStock = styled.div`
    font-size: 0.8rem;
    background-color:#ececec;
    padding-left:4px;
    padding-top:2px;
    margin-top:4px;
`
export const MLabelStock = styled.label`
    font-size: 0.7rem;
    color: #6c757d;
`

export const Quantity = styled.div`
    display: flex;
    flex: 1;
    margin-left: 8px;
    align-items: center;
`;
export const Number = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 10px;
    text-align: center;
`;

export const BtnUpDown = styled.button`
    appearance: none;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 3px 10px;
    border: 2px solid transparent;
    cursor: pointer;
    background-color: rgba(112, 76, 182, 0.1);
    border-radius: 5px;
    color: blue;
`;

export const BtnDelete = styled.button`
    appearance: none;
    font-size: 1rem;
    text-align: center;
    border: none;
    padding: 3px 7px;
    outline: none;
    border: 2px solid transparent;
    cursor: pointer;
    color: rgba(255, 0,0);
    background-color: rgba(215, 219, 221, 0.7);
    border-radius: 5px;
    margin-right: 8px;
`;
export const MLabel = styled.label`
    font-size: 0.9rem;
    color: #6c757d;
`

export const BoxMemo = styled.div`
    flex: 2;
    border: 1px solid rgba(215, 219, 221, 0.7);
    border-radius: 4px;
    padding: 4px 4px;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 0.9rem;
    color: #6c757d;
`;
export const InputDate = styled.input.attrs({ type: 'date' })`
    width: 120px;
    margin-left: 4px;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    font-size: 1rem;
    color: #000;
`

export const InputMemo = styled.input`
    width: 140px;
    margin-left: 8px;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    font-size: 1rem;
    color: #000;
`