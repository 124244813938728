import styled from 'styled-components';


export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
    display: none;

    :checked ~ #idOverlay {
        display: block;
        opacity: 1;
    }

    :checked ~ #idWrapper {
        transform : translateX(0);
        opacity: 1;
    }
`
export const OverLay = styled.label.attrs({ id: 'idOverlay' })`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    animation: fadeIn linear 0.2s;
    @keyframes fadeIn {
        from { opacity: 0;}
        to { opacity: 1;}
    }
    z-index: 95;
`;

export const Wrapper = styled.div.attrs({ id: 'idWrapper' })`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;    
    background-color: white;
    width: 80%;
    max-width: 500px;
    transform : translateX(110%);
    transition: transform linear 0.2s, opacity linear 0.2s;
    opacity: 1;
    z-index: 96;
`
export const Header = styled.div`    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50,205,50,0.4);
`
export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: between;
    padding:  6px 0 0 5px;
`;


export const Title = styled.label`
    flex-grow: 1;
    font-size: 0.9rem; 
    font-weight: bold;   
`;
export const SubTitle = styled.label`
    flex-grow: 1;
    font-size: 0.7rem;
`;

export const BtnClose = styled.label`
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    :active {
        background-color: red;
    }
`

export const SelectedList = styled.div`
    overflow-y:scroll;
`
export const ButtonDiv = styled.div`
    display: flex;
    justify-content: center;

    .ButtonConfirm{
        width: 70%;
        max-width: 250px;
        margin: 20px 0;
        padding: 8px;
        font-size: 1rem;
        font-weight: bold;
        outline: none;
        border:1px solid transparent;
        border-color: var(--morienGreen);
        border-radius: 8px;
        background-color: var(--morienGreen);
        color: white;
        cursor: pointer;
        :active{
            background-color: #32cd32;            
        }

        text-align: center;
        text-decoration: none;
    }
    .BtnHacchu{
        border-color:  #9c27b0;
        background-color: #9c27b0;
    }
`
export const TextArea = styled.textarea`
    width: 96%;
    font-size: 1rem;
    padding: 2px 5px;
    border: 1px solid grey;
    border-radius: 3px;
    resize: none;
`;

export const BtnMemo = styled.button`
    appearance: none;
    font-size: 1rem;
    text-align: center;
    border: none;
    padding: 3px 7px;
    outline: none;
    border: 2px solid transparent;
    cursor: pointer;
    background-color: rgba(215, 219, 221, 0.7);
    border-radius: 5px;
    margin-right: 8px;
`;