import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root{
        --lightGrey: #e6e6e6;
        --norGrey: #cccccc;
        --medGrey: #808080;
        --darkGrey: #666;
        --lightGreen: rgba(143,204,194,0.2);        
        --morienGreen: #009527;        
    }
    
    * {
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    }

    html{
        height: 100%;
    }    
    
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow:hidden;
        margin: 0;
        padding: 0;
        height: 100%;

        #root{
            height: 100%;
        }
    }

`;
