import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import goodsApi from '../../api/goodsApi';
import { MESSAGE } from '../../constants';

export const useGoodsDetails = () => {
    const { id } = useParams();
    const [goodsID, setGoodsID] = useState('');
    const [goodsDetails, setGoodsDetails] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });


    useEffect(() => {
        (async function getGoodsDetails() {
            try {
                setIsLoading(true);
                /**Call Api */
                const params = {
                    id: id,
                }
                const dtRes = await goodsApi.getDetails(params);
                setGoodsDetails(dtRes.data || {});
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        })();
        return;
    }, [id]);

    return { goodsDetails, isLoading, message, setMessage };
}

