import React, { createRef, useEffect } from 'react';
import HeaderBox from '../../components/HeaderBox';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import ClientItem from './components/ClientItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useClient } from './useClient';

const Client = () => {
    const { clientList, termSearch, setTermSearch, isLoading, message, setMessage } = useClient();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [clientList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='お客様検索' showCart />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='お客様名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {clientList?.length > 0 ? (
                    clientList.map((item) => {
                        return (<ClientItem key={item.ClientID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
        </Wrapper>
    )
}

export default Client