import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Content, Line, MLabel, RightControl, Title, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import SelectGoods from '../../../../components/SelectGoods';

const GoodsItem = ({ item }) => {
    const [dialogDisplay, setDialogDisplay] = useState(false);
    const navigate = useNavigate();


    const handleShowDialog = () => {
        if (item?.GoodsSubNum > 0) {
            //商品サブ検索を開く
            navigate(`/goods/${item.GoodsID}/sub`, {});
        } else {
            setDialogDisplay(true);
        }
    }
    return (
        <Wrapper>
            <Content to={`/goods/${item.GoodsID}`} >
                <Title>{item.GoodsName || '-'}</Title>
                <Line>
                    <MLabel>サイズ：</MLabel>
                    {item.GoodsSize || '―'}
                </Line>
            </Content>
            <RightControl>
                <Button onClick={() => { handleShowDialog() }}>選択</Button>
            </RightControl>
            <SelectGoods goods={item} dialogDisplay={dialogDisplay} setDialogDisplay={setDialogDisplay} />
        </Wrapper >
    )
}

GoodsItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default GoodsItem;
