import React, { createRef, useEffect } from 'react';
import HeaderBox from '../../components/HeaderBox';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import SalesSlipItem from './components/SalesSlipItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useSalesSlip } from './useSalesSlip';

const SalesSlip = () => {
    const { clientID, clientName, salesSlipList, termSearch, setTermSearch, isLoading, message, setMessage } = useSalesSlip();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [salesSlipList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={clientID > 5 ? clientName : "伝票検索"} showCart />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='お客様名、現場名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {salesSlipList?.length > 0 ? (
                    salesSlipList.map((item) => {
                        return (<SalesSlipItem key={item.SalesSlipID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
        </Wrapper>
    )
}

export default SalesSlip;