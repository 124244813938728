import { useEffect, useState } from 'react';
import loginApi from '../../api/loginApi';
import { MESSAGE, PW_TAIL, URL_HOME } from '../../constants';
import { LocalStorageSaveStaff, LocalStorageSetToken } from '../../utils';

const initUseInput = {
    userID: '',
    password: ''
}
export const useLogin = () => {
    const [useInput, setUseInput] = useState(initUseInput);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    useEffect(() => {
        (async function login() {
            if (useInput.userID === '' || useInput.password === '') return;
            try {
                setIsLoading(true);
                /**Call Api */
                const loginUser = {
                    userID: useInput.userID,
                    password: useInput.password + PW_TAIL
                }

                const dtResponse = await loginApi.login(loginUser);

                const stUserID = dtResponse?.data['stUserID'] ?? "";
                const stToken = dtResponse?.data['stToken'] ?? "";
                const staffName = dtResponse?.data['staffName'] ?? "";
                const baseName = dtResponse?.data['baseName'] ?? "";

                if (stUserID && stToken) {
                    LocalStorageSetToken(stUserID, stToken);
                    LocalStorageSaveStaff({ staffName: staffName, baseName: baseName });
                    window.location.replace(URL_HOME);
                    setIsLoading(false);
                } else {
                    throw new Error('エラー');
                }
            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: ['ログインに失敗しました。']
                }
                setMessage(errMsg);
            }
        })();

    }, [useInput])

    return { setUseInput, isLoading, message, setMessage };
}