import { useEffect, useRef, useState } from 'react';
import siteApi from '../api/siteApi';
import { MESSAGE } from '../constants';


export const useSiteBox = ({ setIsLoading, setMessage }) => {

    const [termSite, setTermSite] = useState({ txtSearch: '', clientID: 0, siteTypeID: 0 });
    const [siteList, setSiteList] = useState([]);
    //skip first load (init render)
    const didMountedRef = useRef(false);


    useEffect(() => {

        async function searchSite() {
            try {
                //お客様が設定していない。
                if ((termSite?.clientID || 0) < 6) {
                    return;
                }
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSite?.txtSearch || '',
                    clientID: termSite?.clientID || 0,
                    siteTypeID: termSite?.siteTypeID || 0,
                }
                const dtResponse = await siteApi.search(params);
                const dtSite = dtResponse?.data['dtSite'] || [];
                setSiteList([{ SiteID: 1, SiteName: '未入力' }, ...dtSite]);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        }
        if (didMountedRef.current) {
            //skip first load (init render)
            searchSite();
        }
        didMountedRef.current = true;
    }, [termSite, setIsLoading, setMessage])

    return { siteList, termSite, setTermSite };
}