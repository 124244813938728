import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, Title, MLabel, Wrapper } from './styles';

const CompanyItem = ({ item }) => {
    return (
        <Wrapper >
            <Content>
                <Title>{item.ObjNameExtra}</Title>
                <MLabel>
                    〒{item.PostalCode || ''}　{item.Adr || ''}
                </MLabel>


                <Line>
                    <div style={{ display: "inline-block" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <MLabel>TEL: </MLabel><a href={`tel:${item.Tel}`}>{item.Tel || "-"}</a>
                    </div>
                </Line>
            </Content>
        </Wrapper>
    )
}

CompanyItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CompanyItem;
