import React, { useState } from 'react';
import HeaderBox from '../../components/HeaderBox';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import GoodsList from './components/GoodsList';
import SlipInfo from './components/SlipInfo';
import { Content, Wrapper } from './styles';
import { useCart } from './useCart';
import { ORDER_TYPE } from 'constants';
import StockOrder from './components/StockOrder';

const Cart = () => {
    const { cart, orderType, setSalesSlip, setStockOrder, isLoading, setIsLoading, message, setMessage } = useCart();
    const [stepConfirm, setStepConfirm] = useState(1);

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={orderType === ORDER_TYPE.CHUMON ? '注文カート' : '在庫発注カート'} />
            <MessageBox message={message} setMessage={setMessage} />
            <Content>
                {stepConfirm === 1 ? <GoodsList cart={cart} setStepConfirm={setStepConfirm} isHacchu={orderType === ORDER_TYPE.HACCHU} setIsLoading={setIsLoading} setMessage={setMessage} /> : null}
                {/* 注文 */}
                {stepConfirm === 2 && orderType === ORDER_TYPE.CHUMON ? <SlipInfo cart={cart} setSalesSlip={setSalesSlip} setStepConfirm={setStepConfirm} setIsLoading={setIsLoading} setMessage={setMessage} /> : null}
                {/* 在庫発注 */}
                {stepConfirm === 2 && orderType === ORDER_TYPE.HACCHU ? <StockOrder cart={cart} setStockOrder={setStockOrder} setStepConfirm={setStepConfirm} /> : null}
            </Content>
        </Wrapper>
    )
}

export default Cart;