import axiosClient from "./axiosClient";

const goodsSubApi = {
    search(params) {
        const url = '/goods-sub';
        return axiosClient.get(url, { params: params });
    },
}

export default goodsSubApi;
