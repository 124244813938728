import { useEffect, useRef, useState } from 'react';
import clientApi from '../api/clientApi';
import { MESSAGE } from '../constants';


export const useClientBox = ({ setIsLoading, setMessage, noneCredit }) => {

    const [termClient, setTermClient] = useState({ txtSearch: '' });
    const [clientList, setClientList] = useState([]);
    const didMounted = useRef(false);

    useEffect(() => {
        async function searchClient() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termClient?.txtSearch || '',
                    noneCredit
                }
                console.log(params);

                const dtResponse = await clientApi.search(params);
                const dtClient = dtResponse?.data['dtClient'] || [];
                setClientList([{ ClientID: 5, ClientName: '未入力' }, ...dtClient]);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        }
        if (didMounted.current) {
            searchClient();
        }
        didMounted.current = true;
    }, [termClient, setIsLoading, setMessage])

    return { clientList, termClient, setTermClient };
}