import React, { createRef, useEffect } from 'react';
import HeaderBox from 'components/HeaderBox';
import Loading from 'components/Loading';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import GoodsItem from './components/GoodsItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useGoods } from './useGoods';
import { LocalStorageSaveIsStock } from 'utils';

const Goods = ({ isStock }) => {
    const { goodsList, termSearch, setTermSearch, isLoading, message, setMessage } = useGoods(isStock);


    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [goodsList, refTop])

    useEffect(() => {
        if (isStock !== undefined) LocalStorageSaveIsStock(isStock);
    }, [isStock]);

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title='商品選択' showCart={true} />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='商品名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {goodsList?.length > 0 ? (
                    goodsList.map((item) => {
                        return (<GoodsItem key={item.GoodsID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
        </Wrapper>
    )
}

export default Goods