import { CART_KEY, STAFF_KEY, TOKEN_KEY, MEMO_KEY, IS_STOCK_KEY } from "../constants";

export const LocalStorageSetToken = (stUserID, stToken) => {
    const userInfo = {
        stUserID: stUserID,
        stToken: stToken
    }
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
};

export const LocalStorageGetToken = () => {
    const emptyInfo = {
        stUserID: '',
        stToken: '',
    }
    const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || { ...emptyInfo };
    return userInfo;
};

export const LocalStorageRemoveToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
};



// Staff
export const LocalStorageSaveStaff = ({ staffName, baseName }) => {
    const userStaff = {
        staffName: staffName,
        baseName: baseName,
    }
    window.localStorage.setItem(STAFF_KEY, JSON.stringify(userStaff));
};

export const LocalStorageGetStaff = () => {
    const emptyStaff = {
        staffName: '',
        baseName: '',
    }
    const staffInfo = JSON.parse(window.localStorage.getItem(STAFF_KEY)) || { ...emptyStaff };
    return staffInfo;
};

export const LocalStorageRemoveStaff = () => {
    window.localStorage.removeItem(STAFF_KEY);
};


// Cart
export const LocalStorageSaveCart = (cart) => {
    window.localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

export const LocalStorageGetCart = () => {
    const cart = JSON.parse(window.localStorage.getItem(CART_KEY)) || {};
    return cart;
};

export const LocalStorageClearCart = () => {
    window.localStorage.removeItem(CART_KEY);
};

// Temp Memo
export const LocalStorageSaveMemo = (txtMemo) => {
    window.localStorage.setItem(MEMO_KEY, txtMemo);
};

export const LocalStorageGetMemo = () => {
    const txtMemo = window.localStorage.getItem(MEMO_KEY) || "";
    return txtMemo;
};

export const LocalStorageClearMemo = () => {
    window.localStorage.removeItem(MEMO_KEY);
};


export const LocalStorageSaveIsStock = (isStock) => {
    window.localStorage.setItem(IS_STOCK_KEY, JSON.stringify(isStock));
};

export const LocalStorageGetIsStock = () => {
    const isStock = Number(window.localStorage.getItem(IS_STOCK_KEY) || 0);
    return isStock;
};


export const LocalStorageClearIsStock = () => {
    window.localStorage.removeItem(IS_STOCK_KEY);
};


export const LocalStorageClearAll = () => {
    LocalStorageRemoveToken();
    LocalStorageRemoveStaff();
    LocalStorageClearCart();
    LocalStorageClearMemo();
}