import styled from 'styled-components';


export const Wrapper = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
   
`;


export const Content = styled.div`
    flex-grow:1;
    overflow-y: scroll;
    @media screen and (max-width: 768px){
        padding-bottom: 60px;
    }
`;