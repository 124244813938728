import { LocalStorageClearAll, LocalStorageGetToken } from "./utils";

const Auth = {
    isLogin: () => {
        const { stUserID, stToken } = LocalStorageGetToken();
        if (!stUserID || !stToken) {
            LocalStorageClearAll();
            return false;
        }
        return true;
    }
}

export default Auth;
