import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { Line, MLabel, Text, Title, Wrapper, BackButtonWapper, BackButton } from './styles';

const ActionDetails = ({ clientAction, setClientAction }) => {
  return (
    <Wrapper>
      <Line>
        <MLabel>お客様：</MLabel>
        <Title>{clientAction.ClientName || '―'}</Title>
      </Line>
      <Line>
        <MLabel>現場：</MLabel>
        {clientAction.SiteName || '―'}
      </Line>
      <Line>
        <MLabel>訪問計画：</MLabel>
        {clientAction.ActionSchedule || '―'}
      </Line>
      <Line>
        <MLabel>訪問タイトル：</MLabel>
        {clientAction.ClientActionType || '―'}
      </Line>
      <Line>
        <MLabel>訪問者：</MLabel>
        {clientAction.StaffName || '―'}
        &emsp;{clientAction.IsAlt > 1 ? "（サブ）" : "（メイン）"}
      </Line>
      <Line>
        <MLabel>訪問日：</MLabel>
        {clientAction.ActionDate ? clientAction.ActionDate.slice(0, 10) : '－'}
      </Line>
      <Line>
        <MLabel>タイトル：</MLabel>
        {clientAction.ActionTitle || '－'}
      </Line>
      <Line>
        <MLabel>お客様の声：</MLabel>
        {!!clientAction.ClientInfo ? clientAction.ClientInfoType : '－'}

        {!!clientAction.ClientInfo ? <Text>{clientAction.ClientInfo}</Text> : ''}
      </Line>
      <Line>
        <MLabel>ライバル情報：</MLabel>
        {!!clientAction.RivalInfo ? <Text>{clientAction.RivalInfo}</Text> : ''}
      </Line>
      <Line>
        <MLabel>自分・スタッフの考え：</MLabel>
        {!!clientAction.ActionMemo ? <Text>{clientAction.ActionMemo}</Text> : ''}
      </Line>
      <BackButtonWapper>
        <BackButton onClick={() => { setClientAction(null) }}><FontAwesomeIcon icon={faReply} /></BackButton>
      </BackButtonWapper >
    </Wrapper >
  )
}

export default ActionDetails;
