import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, InputBox } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ textHolder, termSearch, setTermSearch }) => {

    const [txtSearch, setTxtSearch] = useState(termSearch?.txtSearch || '');

    function handleSubmit(event) {
        event.preventDefault();
        setTermSearch({ ...termSearch, txtSearch: txtSearch });
    }

    return (
        <Wrapper>
            <InputBox onSubmit={handleSubmit} >
                <input type='text'
                    name='txtSearch'
                    placeholder={textHolder}
                    maxLength={100}
                    value={txtSearch}
                    onChange={(e) => { setTxtSearch(e.target.value); }}
                />
                <button type='submit'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </InputBox>
        </Wrapper>
    )
}

SearchBox.propTypes = {
    textHolder: PropTypes.string,
    termSearch: PropTypes.object.isRequired,
    setTermSearch: PropTypes.func.isRequired,
}

SearchBox.defaultProps = {
    textHolder: 'Search...',
}

export default SearchBox;