import React from 'react';
import PropTypes from 'prop-types';
import { Button, Content, Line, Title, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';

const ClientManagementItem = ({ item }) => {
    const navigate = useNavigate();
    const handleClick = (typeNum) => {
        navigate("/action", { state: { ClientActionTypeID: typeNum, ClientID: item.ClientID, ClientName: item.ClientName } });
    }
    return (
        <Wrapper >
            <Content>
                <Title>{item.ClientName}</Title>
                <Line>
                    〒{item.PostalCode}　{item.Pref || ''}{item.Adr || ''}
                </Line>
                <Line>
                    <div style={{ display: "inline-block" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        TEL: <a href={`tel:${item.Tel}`}>{item.Tel || "-"}</a>
                    </div>
                </Line>
                <Line style={{ marginTop: "4px" }}>
                    <div style={{ display: "inline-block" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <Button onClick={() => { handleClick(1) }} >訪問</Button>
                        <Button onClick={() => { handleClick(101) }} style={{ borderColor: "#9400d3", backgroundColor: "#9400d3", marginLeft: "12px" }}>携帯TEL</Button>
                    </div>
                </Line>
            </Content>
        </Wrapper>
    )
}

ClientManagementItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ClientManagementItem;
