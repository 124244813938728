import styled from 'styled-components';

export const Wrapper = styled.div`
    flex-grow:1;
    display: flex;
    width:100%;
    flex-direction: column;
    padding: 5px 5px;
    overflow-y: scroll;
    margin-bottom: 20px;
`;

export const Title = styled.span`
    color:var(--morienGreen);
    font-weight:bold;
`
export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`

export const Text = styled.div`
    max-height: 110px;
    width: 100%;
    overflow-y: scroll;
    border:1px solid #e6e6e6b5;
    border-radius: 8px;
    background-color: #eee;
    padding: 2px 4px;
`

export const BackButtonWapper = styled.div`
    position: fixed;
    bottom: 8px;
    right: 10px;
`

export const BackButton = styled.button`
    border: none;
    padding: 10px 12px;
    border-radius: 100%;
    color: #000;
    font-size: 20px;
    background-color: rgba(3,3,3,0.2);

    :hover{
        background-color: #00cc00;
        color: #fff;
        transform: scale(1.2);
    }
`