import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-grow: 1;
`

export const Content = styled.div`
    flex-grow:1;
    overflow-y: scroll;
    padding: 0;
`

export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`


export const Info = styled.div`
    padding: 0 5px;
`

