import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DataEmpty, Footer } from './styles'
import CartItem from 'components/HeaderCart/CartItem';
import { useNavigate } from 'react-router-dom';
import stockGoodsApi from 'api/stockGoodsApi';
import { MESSAGE } from 'constants';

const GoodsList = ({ cart, setStepConfirm, isHacchu, setIsLoading, setMessage }) => {
    const navigate = useNavigate();
    const handleBack = () => {
        //Close Cart
        navigate(-1);
    }
    const [goodsList, setGoodsList] = useState(cart?.goodsList);

    useEffect(() => {
        (async function getGoodsDetails() {
            if (!isHacchu) return;
            try {
                setIsLoading(true);
                /**Call Api */
                const params = {
                    goodsList: cart.goodsList,
                }
                const { data } = await stockGoodsApi.getInfoLast(params);
                setGoodsList(data?.goodsList || cart?.goodsList);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            }
        })();
        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHacchu])

    return (
        <>
            {cart?.goodsList.length > 0 ? (
                <>
                    {cart?.goodsList.map((item, index) => {
                        return (<CartItem key={index} item={item} isHacchu={isHacchu} stockGoods={isHacchu ? goodsList[index] : undefined} />)
                    })}
                    <Footer>
                        <Button className='back' onClick={() => { handleBack(); }}>戻る</Button>
                        <Button onClick={() => { setStepConfirm(2); }}>次に</Button>
                    </Footer>
                </>
            ) : (
                <DataEmpty>データなし</DataEmpty >
            )}
        </>
    )
}

GoodsList.propTypes = {
    cart: PropTypes.object.isRequired,
    setStepConfirm: PropTypes.func.isRequired,
};

export default GoodsList;