import React, { useState } from "react";
import PropTypes from "prop-types";
import { BtnClose, BtnQuantity, ButtonSelect, Dialog, DialogContent, DialogTitle, Line, MLabel, Overlay, Quantity, TextContent, TextTile, WrapperQuantity } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { addGoods } from "redux/cartSlice";

const dNext = new Date();
dNext.setDate((new Date()).getDate() + 1);
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;

const SelectGoods = ({ goods, dialogDisplay, setDialogDisplay }) => {

    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();

    const handlePlus = () => {
        setQuantity(preVal => (Number(preVal) + 1));
    }

    const handleMinus = () => {
        if (quantity > 1) {
            setQuantity(preVal => (Number(preVal) - 1));
        }
    }

    const handleSelectGoods = () => {
        const q = quantity;
        if (q < 1) {
            alert("数量：1以上の値を入力してください");
            return;
        }
        const _goods = {
            goodsID: goods.GoodsID,
            goodsName: goods.GoodsName,
            goodsKana: goods.GoodsKana,
            goodsSize: goods.GoodsSize,
            goodsSubID: goods?.GoodsSubID || 1,
            arrivalDate: dNextStr,
            orderMemo: goods?.OrderMemo || '',
            quantity: q,
        }
        dispatch(addGoods(_goods));
        setQuantity(1);
        setDialogDisplay(false);
    }

    return <>
        <Dialog isDisplay={dialogDisplay}>
            <DialogTitle>
                <TextTile>数量選択</TextTile>
                <BtnClose onClick={() => { setDialogDisplay(false); }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
            </DialogTitle>
            <DialogContent>
                <TextContent>
                    <Line>
                        <MLabel>商品：</MLabel>{goods.GoodsName || '―'}
                    </Line>
                    <Line>
                        <MLabel>サイズ：</MLabel>{goods.GoodsSize || '-'}
                    </Line>
                </TextContent>
                <WrapperQuantity>
                    <BtnQuantity onClick={handleMinus}><FontAwesomeIcon icon={faMinus} /></BtnQuantity>
                    <Quantity type="tel" value={`${quantity}`} onChange={(e) => {
                        setQuantity(e.target.value);
                    }} />
                    <BtnQuantity onClick={handlePlus} ><FontAwesomeIcon icon={faPlus} /></BtnQuantity>
                </WrapperQuantity>
                <ButtonSelect onClick={() => { handleSelectGoods(); }} >選択</ButtonSelect>
            </DialogContent>
        </Dialog>
        <Overlay isDisplay={dialogDisplay} />
    </>;
};

SelectGoods.propTypes = {
    goods: PropTypes.object.isRequired,
    dialogDisplay: PropTypes.bool.isRequired,
    setDialogDisplay: PropTypes.func.isRequired
};

export default SelectGoods;
