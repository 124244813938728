import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from 'redux/cartSlice'
import { useLocation, useNavigate } from "react-router-dom";
import orderApi from "api/orderApi";
import { MESSAGE } from "constants";
import stockOrderApi from "api/stockOrderApi";
import * as holiday_jp from '@holiday-jp/holiday_jp';
import dayjs from "dayjs";

export const useCart = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        type: '',
        contents: []
    });
    const cart = useSelector((state) => state.cart);
    const [salesSlip, setSalesSlip] = useState(null);
    const [stockOrder, setStockOrder] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state: { type: orderType } } = useLocation();

    // 社内注文
    useEffect(() => {
        function validate() {
            if ((!salesSlip?.clientID) || salesSlip?.clientID < 6) {
                const err = {
                    type: MESSAGE.ERROR,
                    contents: ['お客様を指定してください。'],
                }

                setMessage(err)
                return false;
            } else if (!salesSlip?.siteID || salesSlip?.siteID < 1) {
                const err = {
                    type: MESSAGE.ERROR,
                    contents: ['現場を指定してください。']
                }
                setMessage(err)
                return false;
            } else if (!salesSlip?.goodsList || salesSlip?.goodsList?.length < 1) {
                const err = {
                    type: MESSAGE.ERROR,
                    contents: ['商品を選択してください。']
                }
                setMessage(err)
                return false;
            } else if (!!salesSlip?.deliveryDate) {
                const holidays = holiday_jp.between(new Date(salesSlip.deliveryDate), new Date(salesSlip.deliveryDate));
                const day = dayjs(salesSlip.deliveryDate).get('day');
                let strMsg = "";
                if (holidays.length > 0) {
                    strMsg = `配送希望日「${salesSlip.deliveryDate}」は祝日（${holidays[0].name}）なので、配達が出来ません。\n他の日をご選択お願いします。`;
                }
                if (day === 0) {
                    strMsg = `配送希望日「${salesSlip.deliveryDate}」は日曜日なので、配達が出来ません。\n他の日をご選択お願いします。`;
                }
                if (!!strMsg) {
                    const err = {
                        type: MESSAGE.ERROR,
                        contents: [strMsg]
                    }
                    setMessage(err)
                    return false;
                }
            }

            //check goods quantity
            const found = salesSlip.goodsList.find((g) => g.quantity < 1);
            if (found) {
                const err = {
                    type: MESSAGE.ERROR,
                    contents: ['商品の数量：1以上の値を入力してください。']
                }
                setMessage(err)
                return false;
            }
            return true;
        }

        async function saveSlip() {
            //validate
            const isValidate = validate();
            if (!isValidate) return;

            try {
                setIsLoading(true);
                const params = {
                    clientID: salesSlip.clientID,
                    siteID: salesSlip.siteID,
                    baseID: salesSlip.baseID,
                    deliveryDate: salesSlip.deliveryDate,
                    deliveryTimeID: salesSlip.deliveryTimeID,
                    deliveryTypeID: salesSlip.deliveryTypeID,
                    orderMemo: salesSlip.orderMemo,
                    goodsList: [...salesSlip.goodsList],
                }
                const dtResponse = await orderApi.save(params);
                const newOrderID = dtResponse?.data['orderID'] || 0;

                if (newOrderID > 0) {
                    //show message => direct to /goods
                    const errMsg = {
                        type: MESSAGE.INFO,
                        contents: ['注文は完了しました。'],
                        handleClose: () => {
                            dispatch(clearCart());
                            navigate("/goods?search=", { replace: true });
                        },
                    }
                    setMessage(errMsg);

                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: ['注文に失敗しました。'],
                }
                setMessage(errMsg);
            }
        }

        if (salesSlip) {
            saveSlip();
        }

    }, [salesSlip, navigate, dispatch]);

    // 在庫発注

    useEffect(() => {
        (async function saveStockOrder() {
            try {
                if (!stockOrder) return;
                setIsLoading(true);
                //validate
                let msg = "";
                if (!stockOrder.goodsList?.length) {
                    msg = "商品を選択してください。";
                } else if (!stockOrder.stockBaseID) {
                    msg = "拠点を選択してください。";
                }
                if (msg) {
                    throw Error(msg);
                }
                //call api
                const params = {
                    stockBaseID: stockOrder.stockBaseID,
                    orderDate: stockOrder.orderDate,
                    goodsList: stockOrder.goodsList
                }
                const { data } = await stockOrderApi.save(params);
                if (!data?.stockOrderID) {
                    throw Error("在庫発注にエラーが発注しました。");
                }
                const errMsg = {
                    type: MESSAGE.INFO,
                    contents: ['在庫発注は完了しました。'],
                    handleClose: () => {
                        dispatch(clearCart());
                        navigate("/goods?search=", { replace: true });
                    },
                }
                setMessage(errMsg);
            } catch (err) {
                const errMsg = {
                    type: MESSAGE.INFO,
                    contents: [err],
                }
                setMessage(errMsg);
            } finally {
                setIsLoading(false);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockOrder])
    return { cart, orderType, setSalesSlip, setStockOrder, isLoading, setIsLoading, message, setMessage };
}

