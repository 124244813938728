import axiosClient from "./axiosClient";

const stockOrderApi = {
    save(params) {
        const url = '/stock-order';
        return axiosClient.post(url, params);
    }
}

export default stockOrderApi;
