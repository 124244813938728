import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonSelect, Line, MLabel, Row, Title, Wrapper } from './styles';
import SelectGoods from '../../../../components/SelectGoods';

const GoodsItem = ({ goods }) => {
    const [dialogDisplay, setDialogDisplay] = useState(false);

    return (
        <Wrapper>
            <Title>
                <Line><MLabel>品名：</MLabel>{goods.GoodsName}</Line>
            </Title>
            <Row>
                <div className='col-left'>
                    <Line>
                        <MLabel>数量：</MLabel>{goods.Number}
                        <MLabel className='pl-30'>サイズ：</MLabel>{goods.GoodsSize}
                    </Line>
                    <Line>
                        <MLabel>単価：</MLabel>{Intl.NumberFormat('ja-JP').format(goods.Price)}円
                    </Line>
                </div>
                <div className='col-right'>
                    <ButtonSelect onClick={() => { setDialogDisplay(true); }} >選択</ButtonSelect>
                </div>
            </Row>
            <SelectGoods goods={goods} dialogDisplay={dialogDisplay} setDialogDisplay={setDialogDisplay} />
        </Wrapper>
    )
}


GoodsItem.propTypes = {
    goods: PropTypes.object,
}

GoodsItem.defaultProps = {
    goods: {},
}

export default GoodsItem
