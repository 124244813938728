import axiosClient from "./axiosClient";

const actionApi = {

    register(params) {
        return axiosClient.post("/action", params);
    },
    search(params) {
        return axiosClient.get("/action-list", { params: params });
    },

}

export default actionApi;
