import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MESSAGE } from 'constants';
import companyApi from 'api/companyApi';


export const useOccation = () => {

    const location = useLocation();
    const objSearch = useMemo(() => {
        const search = new URLSearchParams(location.search);
        const obj = {
            txtSearch: search.get("search") || '',
        };
        return obj;
    }, [location.search]);

    const [termSearch, setTermSearch] = useState({ txtSearch: objSearch?.txtSearch || '' });

    const [companyList, setCompanyList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({
        title: '',
        type: '',
        contents: []
    });

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/company?search=${termSearch.txtSearch}`, { replace: true });
    }, [navigate, termSearch])


    useEffect(() => {
        (async function searchCompany() {
            try {
                setIsLoading(true);

                /**Call Api */
                const params = {
                    txtSearch: termSearch?.txtSearch || '',
                    staffID: termSearch?.staffID || 0,
                }
                const dtResponse = await companyApi.search(params);
                const dtCompany = dtResponse?.data['dtCompany'];
                setCompanyList(dtCompany);

            } catch (err) {
                const errMsg = {
                    type: MESSAGE.ERROR,
                    contents: [err]
                }
                setMessage(errMsg);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [termSearch])

    return { companyList, termSearch, setTermSearch, isLoading, message, setMessage };
}