import React, { createRef, useEffect } from 'react';
import HeaderBox from '../../components/HeaderBox';
import Loading from '../../components/Loading';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import SiteItem from './components/SiteItem';
import { Content, DataEmpty, Wrapper } from './styles';
import { useSite } from './useSite';

const Site = () => {
    const { clientID, clientName, siteList, termSearch, setTermSearch, isLoading, message, setMessage } = useSite();

    const refTop = createRef();
    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [siteList, refTop])
    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title={clientID > 5 ? clientName : '現場検索'} showCart />
            <MessageBox message={message} setMessage={setMessage} />
            <SearchBox textHolder='現場名' termSearch={termSearch} setTermSearch={setTermSearch} />
            <Content ref={refTop}>
                {siteList?.length > 0 ? (
                    siteList.map((item) => {
                        return (<SiteItem key={item.SiteID} item={item} />)
                    })
                ) : (<DataEmpty>データなし</DataEmpty>)}
            </Content>
        </Wrapper>
    )
}

export default Site;