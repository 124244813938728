import axiosClient from "./axiosClient";

const clientApi = {

    search(params) {
        const url = '/client';
        return axiosClient.get(url, { params: params });
    },

    searchManagement(params) {
        const url = '/client-management';
        return axiosClient.get(url, { params: params });
    },

}

export default clientApi;
