import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MLabel, Row, Wrapper, Title, Line, InputDate, Select, Option, TextArea, Footer, Button } from './styles'
import InputSelectBox from 'components/InputSelectBox';
import { useClientBox } from 'hooks/useClientBox';
import { setClient, setSite, setDeliveryTypeID, setBase } from 'redux/cartSlice';
import { useSiteBox } from 'hooks/useSiteBox';
import { DELIVERY_TIME as DeliveryTime, DELIVERY_TYPE as DeliveryType, BASE_LIST as BaseList } from 'constants';


const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;
const dNext = new Date();
dNext.setDate(dNow.getDate() + 1);
const dNextStr = `${dNext.getFullYear()}-${(dNext.getMonth() + 1).toString().padStart(2, '0')}-${dNext.getDate().toString().padStart(2, '0')}`;



const SlipInfo = ({ cart, setSalesSlip, setStepConfirm, setIsLoading, setMessage }) => {
    const dispatch = useDispatch();
    const { clientList, termClient, setTermClient } = useClientBox({ setIsLoading: setIsLoading, setMessage: setMessage });
    const { siteList, termSite, setTermSite } = useSiteBox({ setIsLoading: setIsLoading, setMessage: setMessage });

    useEffect(() => {
        //init メモの内容
        setMemo(cart.deliveryTypeID, cart.siteID, cart.siteName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //お客様選択    
    const renderClientItem = (item) => {
        return (
            <>
                <Title>{item?.ClientName || '-'}</Title>
                < Line >
                    <MLabel>住所：</MLabel>
                    〒{item?.PostalCode}　{item?.Pref || ''}{item?.Adr || ''}
                </Line>
            </>)
    }
    const selectClient = (client) => {
        dispatch(setClient({
            clientID: client.ClientID,
            clientName: client.ClientName,
        }));
    }

    //現場選択

    const renderSiteItem = (item) => {
        return (
            <>
                <Title >{item.SiteName}</Title>
                <Line>
                    <MLabel>住所：</MLabel>
                    〒{item.PostalCode}　{item.AddressPrefecture || ''}{item.AddressCity || ''}{item.AddressNumber || ''}
                </Line>
                <Line>
                    <MLabel>日付：</MLabel>{item.DateBeginning ? item.DateBeginning.slice(0, 10) : '－'}～{item.DateFinished ? item.DateFinished.slice(0, 10) : '－'}
                </Line>
            </>)
    }
    const selectSite = (site) => {
        setMemo(cart.deliveryTypeID, site.SiteID, site.SiteName);
        dispatch(setSite({
            siteID: site.SiteID,
            siteName: site.SiteName,
        }))
    }
    const handleSetTermSite = (_termSite) => {
        const siteTypeID = getSiteTypeID(cart.deliveryTypeID);
        setTermSite({ ..._termSite, clientID: cart.clientID, siteTypeID: siteTypeID });
    }


    // 配送希望
    const [deliveryDate, setDeliveryDate] = useState(dNextStr);
    const [deliveryTimeID, setDeliveryTimeID] = useState(1);
    // メモ
    const [orderMemo, setOrderMemo] = useState('');

    const handleOrder = () => {
        setSalesSlip({
            ...cart,
            clientID: cart?.clientID || 5,  //未入力
            siteID: cart?.siteID || 1,  //未入力
            goodsList: cart?.goodsList ? [...cart.goodsList] : [],
            baseID: cart?.baseID || 1,  //未入力
            deliveryDate: deliveryDate,
            deliveryTimeID: deliveryTimeID,
            orderMemo: orderMemo.trim(),
        });
    }

    const handleBaseChange = (e) => {
        const baseID = Number(e.target.value);
        //拠点ID保存
        dispatch(setBase(baseID));
    }

    const handleDeliveryTypeChange = (e) => {
        const typeID = Number(e.target.value);
        setMemo(typeID, 1, "未入力");
        // 入力した現場を削除する
        dispatch(setSite({ SiteID: 1, SiteName: "未入力" }));
        //配送タイプ
        dispatch(setDeliveryTypeID(typeID));
    }

    const setMemo = (_deliveryTypeID, _siteID, _siteName) => {
        let memo = "";
        let siteName = "";
        if (_siteID > 1) siteName = _siteName;
        switch (Number(_deliveryTypeID)) {
            case 2:
                // 現場届け
                memo = _siteID > 1 ? (siteName) : ("現場住所、現場名を記載して下さい"); //("現場届け　" + siteName) : ("現場住所、現場名を記載して下さい");
                break;
            case 3:
                // 店届け
                memo = siteName; //"御店届け　" + siteName;
                break;
            case 4:
                // 倉庫届け
                memo = siteName;     //"倉庫届け　" + siteName;
                break;
            case 5:
                // 引取り
                memo = "";//"引取り";
                break;
            case 7:
                // 全国物流網
                memo = "";//"物流";
                break;
            case 8:
                // モリエンから運送会社
                memo = "";//"発送";
                break;
            case 9:
                // メーカーから
                memo = "";//"直送";
                break;
            case 10:
                // 売上商品を当店で預ります
                memo = "";//"預り";
                break;
            case 20:
                // 商品の動きはなく、金額のみ処理します
                memo = "";//"値引き"
                break;
            default:
                memo = "";
                break;
        }

        setOrderMemo(memo);
    }

    const getSiteTypeID = (_deliveryTypeID) => {
        switch (Number(_deliveryTypeID)) {
            case 2:
                // 2-現場へ配達 -> 1-一般現場
                return 1;
            case 3:
                // 3-店、家、事務所へ -> 2-御店
                return 2;
            case 4:
                //4-倉庫へ配達 -> 3-倉庫
                return 3;
            default:
                break;
        }
        return 0;
    }

    return (
        <Wrapper>
            {/*お客様選択 */}
            <Row column>
                <MLabel >お客様：</MLabel>
                <InputSelectBox title='お客様を選択してください'
                    name={cart?.clientName || '未入力'}
                    textHolder={'お客様名'}
                    termSearch={termClient}
                    setTermSearch={setTermClient}
                    resultList={clientList}
                    renderItem={renderClientItem}
                    selectItem={selectClient}
                    classNameCss={'pl-20'}
                >
                </InputSelectBox>
            </Row>

            {/* 配達タイプ */}
            <Row column>
                <MLabel>配達タイプ：</MLabel>
                <Select
                    value={cart?.deliveryTypeID || 1}
                    onChange={(e) => { handleDeliveryTypeChange(e); }}
                >
                    {DeliveryType.map((item) => {
                        return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                    })}
                </Select>
            </Row>

            {/* 拠点 */}
            <Row column>
                <MLabel>拠点：</MLabel>
                <div style={{ display: 'flex' }}>
                    <Select
                        value={cart?.baseID || 1}
                        onChange={handleBaseChange}
                    >
                        {BaseList.map((item) => {
                            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                        })}
                    </Select>
                    {cart?.deliveryTypeID === 5 ? (<>
                        <span className='sub-label'> ※引取りをご希望の場合、引取り希望の拠点を選択お願い致します。</span>
                    </>) : null}
                </div>
            </Row>


            {/* 現場選択 */}
            {/* 2-現場届け | 3-店届け  | 4-倉庫届け 　→ 現場表示*/}
            {[2, 3, 4].includes(Number(cart.deliveryTypeID || 1)) ?
                <Row column>
                    <MLabel>現場：</MLabel>
                    <InputSelectBox title='現場を選択してください'
                        name={cart?.siteName || '未入力'}
                        textHolder={'現場名'}
                        termSearch={termSite}
                        setTermSearch={handleSetTermSite}
                        resultList={siteList}
                        renderItem={renderSiteItem}
                        selectItem={selectSite}
                        classNameCss={'pl-20'}
                    >
                    </InputSelectBox>
                </Row>
                : null}

            {/* お届け先 */}
            {/* <Row column>
                <MLabel>お届け先：..........作成中</MLabel>
                <div>
                    <button>変更</button>
                </div>
            </Row> */}

            {/* 配送希望 */}
            <Row column>
                <MLabel>配達希望日：</MLabel>
                <InputDate
                    min={dNowStr}
                    max={'2099-12-31'}
                    value={deliveryDate}
                    onChange={(e) => { setDeliveryDate(e.target.value); }}
                />
            </Row>

            {/* 配達希望時 */}
            <Row column>
                <MLabel>配達希望時：</MLabel>
                <Select
                    value={deliveryTimeID}
                    onChange={(e) => { setDeliveryTimeID(e.target.value) }}
                >
                    {DeliveryTime.map((item) => {
                        return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                    })}
                </Select>
            </Row>

            {/* メモ */}
            <Row column>
                <MLabel>メモ：</MLabel>
                <TextArea
                    maxLength={100}
                    rows={4}
                    value={orderMemo}
                    onChange={(e) => { setOrderMemo(e.target.value) }} />
            </Row>

            <Footer>
                <Button className='back' onClick={() => { setStepConfirm(1); }}>戻る</Button>
                <Button onClick={() => { handleOrder(); }} >登録</Button>
            </Footer>
        </Wrapper>
    )
}

SlipInfo.propTypes = {
    cart: PropTypes.object.isRequired,
    setSalesSlip: PropTypes.func.isRequired,
    setStepConfirm: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
};

export default SlipInfo;