import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SHeader, SalesGoods } from './styles';
import GoodsItem from '../GoodsItem';


const GoodsList = ({ goodsList }) => {
    return (
        <Wrapper>
            <SHeader>商品一覧</SHeader>
            <SalesGoods>
                {goodsList.map(item => (
                    <GoodsItem key={item.SalesGoodsID} goods={item} />
                ))}
            </SalesGoods>
        </Wrapper>
    )
}

GoodsList.propTypes = {
    goodsList: PropTypes.array,
}

GoodsList.defaultProps = {
    goodsList: []
}

export default GoodsList;
