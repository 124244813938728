import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { MLabel, Row, Wrapper, InputDate, Select, Option, TextArea, Footer, Button } from './styles'
import { HACCHU_TEN as BaseList } from 'constants';


const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;

const StockOrder = ({ cart, setStockOrder, setStepConfirm }) => {
    const [stockBaseID, setStockBaseID] = useState(1);
    const [orderDate, setOrderDate] = useState(dNowStr);

    const handleOrder = () => {
        setStockOrder({
            stockBaseID,
            orderDate,
            goodsList: [...cart.goodsList]
        })
    }

    return (
        <Wrapper>
            {/* 拠点 */}
            <Row column>
                <MLabel>拠点：</MLabel>
                <div style={{ display: 'flex' }}>
                    <Select
                        value={stockBaseID}
                        onChange={(e) => { setStockBaseID(e.target.value) }}
                    >
                        {BaseList.map((item) => {
                            return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                        })}
                    </Select>
                </div>
            </Row>

            <Row column>
                <MLabel>発注日：</MLabel>
                <InputDate
                    min={dNowStr}
                    max={'2099-12-31'}
                    value={orderDate}
                    onChange={(e) => { setOrderDate(e.target.value); }}
                />
            </Row>
            <Footer>
                <Button className='back' onClick={() => { setStepConfirm(1); }}>戻る</Button>
                <Button onClick={() => { handleOrder(); }} >発注</Button>
            </Footer>
        </Wrapper>
    )
}

StockOrder.propTypes = {
    cart: PropTypes.object.isRequired,
    setStepConfirm: PropTypes.func.isRequired,
};

export default StockOrder;